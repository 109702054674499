import { main } from "./axiosInstances";
import store from "@/store/store";

main.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 403 && error.response?.data?.code === 1) {
      store.commit("setRestricted", true);
    }
    if (error.response && error.response.status === 401) {
      store.dispatch("auth/logout");
    }
    return Promise.reject(error);
  }
);

export default main;
