import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useCurrency } from "@/composables/useCurrency";
import { CURRENCIES } from "@/constants/currencies";

import { computed } from 'vue';
export default {
  __name: 'CurrencySelector',
  props: {
    header: Boolean,
    disabled: Boolean
  },
  setup: function setup(__props) {
    var _useCurrency = useCurrency(),
      convertTo = _useCurrency.convertTo,
      convertToView = _useCurrency.convertToView,
      setConvertTo = _useCurrency.setConvertTo;
    var currencies = [{
      text: CURRENCIES.USD,
      value: CURRENCIES.USDT
    }, {
      text: CURRENCIES.BTC,
      value: CURRENCIES.BTC
    }, {
      text: CURRENCIES.ETH,
      value: CURRENCIES.ETH
    }];
    var activeCurrency = computed(function () {
      var _convertToView$value, _convertTo$value;
      return {
        text: (_convertToView$value = convertToView.value) !== null && _convertToView$value !== void 0 ? _convertToView$value : CURRENCIES.USD,
        value: (_convertTo$value = convertTo.value) !== null && _convertTo$value !== void 0 ? _convertTo$value : CURRENCIES.USDT
      };
    });
    function setConvertToCurrency(currency) {
      setConvertTo(currency);
    }
    return function (_ctx, _cache) {
      var _component_PBIcon = _resolveComponent("PBIcon");
      var _component_PBDropdown = _resolveComponent("PBDropdown");
      return _openBlock(), _createBlock(_component_PBDropdown, {
        "model-value": _unref(activeCurrency),
        options: currencies,
        top: !__props.header,
        left: !__props.header,
        bordered: !__props.header,
        title: "Base currency",
        "class": _normalizeClass({
          'opacity-50 pointer-events-none': __props.disabled
        }),
        width: "180px",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function (value) {
          return setConvertToCurrency(value.value);
        })
      }, {
        icon: _withCtx(function () {
          return [_createVNode(_component_PBIcon, {
            name: "coins",
            w: 20,
            h: 20
          })];
        }),
        _: 1
      }, 8, ["model-value", "top", "left", "bordered", "class"]);
    };
  }
};