import store from "@/store/store";

let transactions = [];
let timeout = null;

export default (payload) => {
  clearTimeout(timeout);

  transactions = [...payload, ...transactions];

  if (!transactions.length) {
    return;
  }

  timeout = setTimeout(() => {
    store.dispatch("transactions/add", transactions);
    transactions = [];
  }, 100);
};
