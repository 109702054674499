import "core-js/modules/es.array.join.js";
import "core-js/modules/es.string.small.js";
import { unref as _unref, isRef as _isRef, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue";

import { useVModel } from '@vueuse/core';
import { computed } from 'vue';
var _hoisted_1 = ["for"];
var _hoisted_2 = ["id", "checked", "disabled"];
export default {
  __name: 'PBCheckbox',
  props: {
    modelValue: Boolean,
    label: {
      type: String,
      "default": ""
    },
    disabled: Boolean,
    error: {
      type: String,
      "default": ""
    },
    small: Boolean
  },
  emits: ["update:modelValue"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var value = useVModel(props, "modelValue", emit);
    var name = computed(function () {
      return props.label.split(" ").join("-").toLowerCase();
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("label", {
        "for": _unref(name),
        "class": _normalizeClass(["inline-flex cursor-pointer items-center", {
          'opacity-50 pointer-events-none': __props.disabled
        }])
      }, [_withDirectives(_createElementVNode("input", {
        id: _unref(name),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(value) ? value.value = $event : null;
        }),
        checked: _unref(value),
        disabled: __props.disabled,
        type: "checkbox",
        "class": "hidden"
      }, null, 8, _hoisted_2), [[_vModelCheckbox, _unref(value)]]), _createElementVNode("span", {
        "class": _normalizeClass(["after:border-l-2 after:border-b-2 after:border-solid after:rounded-[2px] after:absolute after:top-[3px] after:left-1/2 after:w-[6px] after:h-[4px] after:translate-x-[-50%] after:rotate-[-45deg] relative min-w-[16px] w-[16px] min-h-[16px] h-[16px] mr-md rounded-[4px] border-2 border-solid after:transition-transform", {
          'border-white after:border-white after:scale-0': !_unref(value) && !__props.error,
          'border-primary after:scale-[1] after:border-primary': _unref(value),
          'border-danger after:border-danger after:scale-0': __props.error
        }])
      }, null, 2), _createElementVNode("span", {
        "class": _normalizeClass(["font-semibold", {
          'text-white': !__props.error,
          'text-danger': __props.error,
          'text-sm': __props.small,
          'text-base': !__props.small
        }])
      }, [!_ctx.$slots["default"] ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createTextVNode(_toDisplayString(__props.label), 1)], 64)) : _renderSlot(_ctx.$slots, "default", {
        key: 1
      })], 2)], 10, _hoisted_1);
    };
  }
};