import client from "@/utils/axios";

function getOrderKey(order) {
  const { symbol, orderId, marketType, side } = order;
  return `${symbol}:${orderId}:${marketType}:${side}`; 
}

function getPositionKey(position) {
  const { symbol, positionSide } = position;
  return `${symbol}${positionSide}`
}

export default {
  namespaced: true,

  state: {
    openOrders: [],
    openPositions: [],
  },

  actions: {
    async getOpenOrders({ state, commit }, params) {
      try {
        const { data } = await client.get("/api/trading/exchange/open-orders", {
          params,
        });

        const orderKeyMap = state.openOrders.reduce((memo, order) => {
          const key = getOrderKey(order);
          memo[key] = order;
          return memo;
        }, {});

        const computedOrders = data.map((order) => {
          const key = getOrderKey(order);
          const existingOrder = orderKeyMap[key];
          const isCancelling = existingOrder ? existingOrder.isCancelling : false

          return {
            ...order,
            key,
            isCancelling
          };
        });

        commit("setOpenOrders", computedOrders || []);
      } catch {
        commit("setOpenOrders", []);
      }
    },
    
    async cancelOrder({ state, commit }, { apiKeyId, order }) {
      const key = getOrderKey(order);
      const index = state.openOrders.findIndex((order) => order.key === key);

      if (index === -1) {
        throw new Error('Order not found');
      } 
      
      state.openOrders[index].isCancelling = true;
      
      commit("setOpenOrders", state.openOrders.slice());

      const { marketType, symbol, orderId } = order;

      const params = {
        apiKeyId,
        orderId,
        marketType,
        symbol,
      }

      await client.delete(`/api/trading/exchange/order`, {
        params
      });

      state.openOrders.splice(index, 1)

      commit("setOpenOrders", state.openOrders.slice());
    },

    async cancelAllOrders({ state, commit }, params) {
      const updatedOrders = state.openOrders.map((order) => {
        return Object.assign(order, { isCancelling: true });
      });

      commit("setOpenOrders", updatedOrders);

      return client.delete(`/api/trading/exchange/orders`, {
        params
      });
    },

    async getOpenPositions({ state, commit }, params) {
      try {
        const { data } = await client.get("/api/trading/exchange/open-positions", {
          params,
        });

        const positionKeyMap = state.openPositions.reduce((memo, position) => {
          const key = getPositionKey(position);
          memo[key] = position;
          return memo;
        }, {});

        const computedPositions = data.map((position) => {
          const key = getPositionKey(position);
          const existingPosition = positionKeyMap[key];
          const isClosing = existingPosition ? existingPosition.isClosing : false

          return {
            ...position,
            key,
            isClosing
          };
        });

        commit("setOpenPositions", computedPositions || []);
      } catch {
        commit("setOpenPositions", []);
      }
    },

    async closePosition({ state, commit }, { apiKeyId, position }) {
      const key = getPositionKey(position);
      const index = state.openPositions.findIndex((item) => item.key === key);

      if (index === -1) {
        throw new Error('Position not found');
      } 
      
      state.openPositions[index].isClosing = true;
      
      commit("setOpenPositions", state.openPositions.slice());

      const { symbol, positionSide, quantity } = position;

      const params = {
        apiKeyId,
        symbol, 
        positionSide,
        quantity
      };

      await client.delete(`/api/trading/exchange/position`, {
        params
      });

      state.openPositions.splice(index, 1)

      commit("setOpenPositions", state.openPositions.slice());
    },

    async closeAllPositions({ state, commit }, params) {
      const updatedPositions = state.openPositions.map((position) => {
        return Object.assign(position, { isClosing: true });
      });

      commit("setOpenPositions", updatedPositions);

      return client.delete(`/api/trading/exchange/positions`, {
        params
      });
    },
  },

  getters: {
    formattedOpenOrders(state) {
      return state.openOrders.map((item) => {
        const { marketType } = item;

        const formattedMarketType = marketType === 'spot'
          ? 'Spot' : 'Futures';

          return {
          ...item,
          formattedMarketType
        };
      });
    }
  },

  mutations: {
    setOpenOrders(state, data) {
      state.openOrders = data;
    },

    setOpenPositions(state, data) {
      state.openPositions = data;
    },
  },
};
