var body = document.querySelector("body");
var container = document.querySelector(".layout-container");
export function useBodyScroll(refToWatch) {
  watch(refToWatch, function (value) {
    if (value) {
      body.classList.add("overflow-hidden");
      container && container.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
      container && container.classList.remove("overflow-hidden");
    }
  }, {
    immediate: true
  });
  onBeforeUnmount(function () {
    body.classList.remove("overflow-hidden");
    container && container.classList.remove("overflow-hidden");
  });
}