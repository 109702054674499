import client from "@/utils/axios";

import { useCurrency } from "@/composables/useCurrency";

export default {
  namespaced: true,

  state: {
    trades: [],
    bars: [],
    volumes: [],
    fees: [],
    feesVsTurnoverBars: [],
    feesTakerMaker: [],
    tcaByExchange: [],
    tcaByContractType: [],
    chargedFees: {},
  },

  actions: {
    async getTurnoverReportTrades({ commit }, params) {
      try {
        const { data } = await client.get("/api/v2/turnover/trades", {
          params,
        });

        const trades = data?.trades || [];

        return params.returnTrades
          ? trades
          : commit("setTurnoverReportTrades", trades);
      } catch {
        commit("setTurnoverReportTrades", []);
      }
    },

    async getTurnoverReportBars({ commit }, params) {
      try {
        const { data } = await client.get("/api/turnover/bars", { params });

        commit("setTurnoverReportBars", data?.bars || []);
      } catch {
        commit("setTurnoverReportBars", []);
      }
    },

    async getTurnoverReportVolumes({ commit }, params) {
      try {
        const { data } = await client.get("/api/turnover/volumes", { params });

        commit("setTurnoverReportVolumes", data?.volumes || []);
      } catch {
        commit("setTurnoverReportVolumes", []);
      }
    },

    async getFeesVsTurnoverBars({ commit }, params) {
      try {
        const { data } = await client.get("/api/turnover/bars", { params });

        commit("setFeesVsTurnoverBars", data?.bars || []);
      } catch {
        commit("setFeesVsTurnoverBars", []);
      }
    },

    async getFees({ commit }, params) {
      try {
        const { data } = await client.get("/api/reporting/fee/bars", {
          params,
        });

        commit("setFees", data?.bars || []);
      } catch {
        commit("setFees", []);
      }
    },

    async getFeesTakerMaker({ commit }, params) {
      try {
        const { data } = await client.get("/api/reporting/fee/maker", {
          params,
        });

        commit("setFeesTakerMaker", data?.maker || []);
      } catch {
        commit("setFeesTakerMaker", []);
      }
    },

    async getTCAByExchange({ commit }, params) {
      try {
        const { data } = await client.get("/api/reporting/bpsfee/bars", {
          params,
        });

        commit("setTCAByExchange", data?.bars || []);
      } catch {
        commit("setTCAByExchange", []);
      }
    },

    async getTCAByContractType({ commit }, params) {
      try {
        const { data } = await client.get("/api/reporting/bpsfee/maker", {
          params,
        });

        commit("setTCAByContractType", data?.maker || []);
      } catch {
        commit("setTCAByContractType", []);
      }
    },

    async fetchChargedFees({ commit }, params) {
      try {
        const { data } = await client.get("/api/v2/turnover/charged", {
          params,
        });

        commit("setChargedFees", data || {});
      } catch {
        commit("setChargedFees", {});
      }
    },
  },

  getters: {
    feesTakerMaker(state) {
      const { convertToView } = useCurrency();

      return state.feesTakerMaker.sort((a, b) => {
        return (
          b.totalFees[convertToView.value] - a.totalFees[convertToView.value]
        );
      });
    },

    tcaByExchange(state) {
      return state.tcaByExchange.sort((a, b) => {
        return b.totalBpsFee.USD - a.totalBpsFee.USD;
      });
    },

    tcaByContractType(state) {
      return state.tcaByContractType.sort((a, b) => {
        return b.totalBpsFee.USD - a.totalBpsFee.USD;
      });
    },

    chargedFeesTotal(state) {
      return state.chargedFees?.amounts?.payments ?? {};
    },
  },

  mutations: {
    setTurnoverReportTrades(state, trades) {
      state.trades = trades;
    },

    setTurnoverReportBars(state, bars) {
      state.bars = bars;
    },

    setTurnoverReportVolumes(state, volumes) {
      state.volumes = volumes;
    },

    setFees(state, fees) {
      state.fees = fees;
    },

    setFeesVsTurnoverBars(state, feesVsTurnoverBars) {
      state.feesVsTurnoverBars = feesVsTurnoverBars;
    },

    setFeesTakerMaker(state, feesTakerMaker) {
      state.feesTakerMaker = feesTakerMaker;
    },

    setTCAByExchange(state, tcaByExchange) {
      state.tcaByExchange = tcaByExchange;
    },

    setTCAByContractType(state, tcaByContractType) {
      state.tcaByContractType = tcaByContractType;
    },

    setChargedFees(state, data) {
      state.chargedFees = data;
    },
  },
};
