import client from "@/utils/axios";

import eventBus from "@/utils/eventbus";
import isDeepEqual from "lodash/isEqual";
import { GLOBAL_EVENTS } from "@/constants/global-events";
import {formatCurrencyCasing} from "@/utils/helpers";
import {formatTradesForTable} from "@/utils/formatters";

export default {
  namespaced: true,

  state: {
    candles: [],
    instruments: [],
    ordersHistory: [],
    openOrders: [],
    exchangeOpenOrders: [],
    exchangeOpenPositions: [],
    marketTrades: [],
    orderbook: {},
    tradingKeys: {},
  },

  actions: {
    async getChartData({ commit }, params) {
      try {
        const { data } = await client.get("/api/trading/candles", { params });

        commit("setCandles", data || []);
      } catch {
        commit("setCandles", []);
      }
    },

    async getInstruments({ commit }, params) {
      try {
        const { data } = await client.get("/api/trading/instruments", {
          params,
        });

        commit("setInstruments", data || []);
      } catch {
        commit("setInstruments", []);
      }
    },

    async getOrderBook({ commit }, params) {
      try {
        const { data } = await client.get("/api/trading/orderbook", { params });

        commit("setOrderBook", data || {});
      } catch {
        commit("setOrderBook", []);
      }
    },

    async getOpenOrders({ commit }, params) {
      try {
        const { data } = await client.get("/api/trading/open-orders", {
          params,
        });

        commit("setOpenOrders", data || []);
      } catch {
        commit("setOpenOrders", []);
      }
    },

    async getExchangeOpenOrders({ commit }, params) {
      try {
        const { data } = await client.get("/api/trading/exchange/open-orders", {
          params,
        });

        commit("setExchangeOpenOrders", data || []);
      } catch {
        commit("setExchangeOpenOrders", []);
      }
    },

    async getExchangeOpenPositions({ commit }, params) {
      try {
        const { data } = await client.get("/api/trading/exchange/open-positions", {
          params,
        });

        commit("setExchangeOpenPositions", data || []);
      } catch {
        commit("setExchangeOpenPositions", []);
      }
    },

    async getOrdersHistory({ commit }, params) {
      try {
        const { data } = await client.get("/api/trading/orders", { params });

        commit("setOrdersHistory", data || []);
      } catch {
        commit("setOrdersHistory", []);
      }
    },

    async getTradingKeys({ commit }, params) {
      try {
        const { data } = await client.get("/api/trading/apikey-mappings", {
          params,
        });

        commit("setTradingKeys", data || {});
      } catch {
        commit("setTradingKeys", {});
      }
    },

    async getMarketTrades({ commit }, params) {
      try {
        const { data } = await client.get("/api/trading/market-trades", {
          params
        })

        const sortedData = data.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)

        commit("setMarketTrades", sortedData || {});
      } catch {
        commit("setMarketTrades", {});
      }

    },

    async makeOrder(_, { payload, apiKeyId }) {
      await client.post(`/api/trading/orders?apiKeyId=${apiKeyId}`, payload);
    },

    async cancelOrder(_, { clientOrderId, apiKeyId }) {
      await client.delete(
        `/api/trading/orders/${clientOrderId}?apiKeyId=${apiKeyId}`
      );
    },

    async cancelAllOrders(_, { apiKeyId }) {
      await client.delete(`/api/trading/orders/`, {
        params: {
          apiKeyId,
        },
      });
    },

    updateLastCandle({ state, commit }, payload) {
      const { candles } = state;

      const existingLastCandle = candles.find(
        (candle) => candle.timestamp === payload.timestamp
      );

      if (existingLastCandle) {
        const index = candles.findIndex(
          (candle) => candle.timestamp === payload.timestamp
        );
        const updated = [...candles];
        updated[index] = payload;
        commit("setCandles", updated);
      } else {
        candles.push(payload);
      }

      eventBus.emit(GLOBAL_EVENTS.UPDATE_CANDLE, payload);
    },

    updateMarketTrades({ state, commit }, payload) {
      let marketTrades = [payload, ...state.marketTrades]
      if(marketTrades.length > 100) {
        marketTrades = marketTrades.slice(0, 100)
      }
      const sortedMarketTrades = marketTrades.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
      commit("setMarketTrades", sortedMarketTrades);
    },

    updateTradingOrder({ state, commit }, payload) {
      const { openOrders, ordersHistory } = state;

      const order = openOrders.find(
        (item) =>
          item.clientOrderId === payload.clientOrderId &&
          item.apiKeyId === payload.apiKeyId
      );

      if (!order) {
        return;
      }

      const index = openOrders.findIndex(
        (item) =>
          item.clientOrderId === payload.clientOrderId &&
          item.apiKeyId === payload.apiKeyId
      );

      if (payload.status === "canceled" || payload.status === "filled") {
        const updated = {
          ...order,
          ...payload,
        };

        const updatedHistory = [updated, ...ordersHistory];
        openOrders.splice(index, 1);

        commit("setOpenOrders", openOrders);
        return commit("setOrdersHistory", updatedHistory);
      }

      const updated = [...openOrders];
      const newItem = {
        ...updated[index],
        ...payload,
      };

      updated[index] = newItem;

      commit("setOpenOrders", updated);
    },
  },

  getters: {
    candles(state) {
      return state.candles.map((item) => {
        const value = {
          ...item,
          time: item.timestamp,
        };
        delete value.timestamp;
        return value;
      });
    },

    tradingAccounts(_, __, rootState, rootGetters) {
      const { exchangeNames } = rootGetters;

      const filtered =
        rootState.portfolio.activePortfolio?.ApiKeys?.filter(
          (item) => item?.restrictions?.trading_enabled && item?.active === true
        ) ?? [];

      const listedExchanges = [
        exchangeNames.BINANCE_OMS,
        exchangeNames.BEQUANT_OMS,
        exchangeNames.HITBTC_OMS,
        exchangeNames.KRAKEN_OMS,
      ];

      return filtered.reduce((memo, current) => {
        if (!listedExchanges.some((item) => item === current.exchangeId)) {
          return memo;
        }

        if (!memo[current.exchangeId]) {
          memo[current.exchangeId] = [];
        }

        memo[current.exchangeId].push(current);

        return memo;
      }, {});
    },

    canTrade(_, getters) {
      return Object.keys(getters.tradingAccounts)?.length > 0;
    },
    canTradeBinance(_, getters, __, rootGetters) {
      const { exchangeNames } = rootGetters;
      return getters.tradingAccounts[exchangeNames?.BINANCE_OMS]?.length > 0;
    },
    marketTrades(state, _, __, rootGetters) {
      const {
        newTrades,
      } = state;

      let formattedTrades = [...newTrades, ...state.trades].map(
        (trade) => {
          const baseCurrencyFormatted = formatCurrencyCasing(
            trade.baseCurrency
          );
          const quoteCurrencyFormatted = formatCurrencyCasing(
            trade.quoteCurrency
          );
          const feeCurrencyFormatted = formatCurrencyCasing(trade.feeCurrency);

          const baseKey = `${trade.exchangeId}:${baseCurrencyFormatted}`;
          const quoteKey = `${trade.exchangeId}:${quoteCurrencyFormatted}`;
          const feeKey = `${trade.exchangeId}:${feeCurrencyFormatted}`;
          const instrument =
            trade.contractType !== "spot"
              ? trade.symbol
              : `${baseCurrencyFormatted}/${quoteCurrencyFormatted}`;
          const { name: apiKeyName } =
          rootGetters["portfolio/getApiKey"](trade.apiKeyId) ?? {};
          const finalFee = feeCurrencyFormatted
            ? feeCurrencyFormatted.replace(/F0/g, "")
            : feeCurrencyFormatted;

          return {
            ...trade,
            baseCurrency:
              rootGetters.currencyToAliasMap[baseKey] ?? baseCurrencyFormatted,
            quoteCurrency:
              rootGetters.currencyToAliasMap[quoteKey] ??
              quoteCurrencyFormatted,
            feeCurrency: rootGetters.currencyToAliasMap[feeKey] ?? finalFee,
            instrument: instrument ? instrument.replace(/F0/g, "") : instrument,
            apiKeyName,
            exchangeFullName: rootGetters.getExchangeFullName(trade.exchangeId),
          };
        }
      );

      return formatTradesForTable(formattedTrades);
    },
  },

  mutations: {
    setCandles(state, data) {
      state.candles = data;
    },

    setInstruments(state, data) {
      state.instruments = data;
    },

    setOpenOrders(state, data) {
      state.openOrders = data;
    },

    setExchangeOpenOrders(state, data) {
      state.exchangeOpenOrders = data;
    },

    setExchangeOpenPositions(state, data) {
      state.exchangeOpenPositions = data;
    },

    setOrdersHistory(state, data) {
      state.ordersHistory = data;
    },

    setTradingKeys(state, data) {
      state.tradingKeys = data;
    },

    setOrderBook(state, data) {
      if (!isDeepEqual(state.orderbook, data)) {
        state.orderbook = data;
      }
    },

    setMarketTrades(state, data) {
      state.marketTrades = data;
    },
  },
};
