import { useBanners } from "@/composables/useBanners";

const { addBanner, removeBanner } = useBanners();

const SHOW_TIMEOUT = 120000;
const HIDE_TIMEOUT = 15000;
const ATTEMPTS_LIMIT = 5;

export default {
  showTimeoutId: null,
  hideTimeoutId: null,
  reconnectAttempts: 0,

  show(immediate) {
    if (!this.showTimeoutId && !immediate) {
      return (this.showTimeoutId = setTimeout(() => {
        addBanner({ type: "connection-lost" });
      }, SHOW_TIMEOUT));
    }

    if (this.reconnectAttempts === ATTEMPTS_LIMIT) {
      addBanner({ type: "connection-lost" });
    }
  },

  hide() {
    clearTimeout(this.showTimeoutId);
    this.showTimeoutId = null;

    this.hideTimeoutId = setTimeout(() => {
      removeBanner({ type: "connection-lost" });
      clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = null;
    }, HIDE_TIMEOUT);
  },

  incrementAttempts() {
    this.reconnectAttempts++;
  },

  resetAttempts() {
    this.reconnectAttempts = 0;
  },
};
