import store from "@/store/store";

const getDefaultRoute = () => {
  return store.getters.hasPermission(store.state.config.userPermissions.PAGES_DASHBOARD) ? '/dashboard' : '/loans'
};

export default (permission) => (_, __, next) => {
  if (!store.getters.hasPermission(permission)) {
    return next({
      path: getDefaultRoute()
    });
  }

  next();
};
