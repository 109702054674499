import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";

import { inject } from 'vue';
export default {
  __name: 'PBTab',
  props: {
    label: {
      type: String,
      required: true
    },
    black: Boolean,
    fullBlack: Boolean
  },
  setup: function setup(__props) {
    var selectedTab = inject("selectedTab");
    return function (_ctx, _cache) {
      return __props.label === _unref(selectedTab) ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        "class": _normalizeClass(["PBTab w-full", {
          'bg-background': !__props.black && !__props.fullBlack,
          'bg-secondary': __props.black,
          'bg-transparent': __props.fullBlack
        }])
      }, [_renderSlot(_ctx.$slots, "default")], 2)) : _createCommentVNode("", true);
    };
  }
};