import axios from "axios";

import client from "@/utils/axios";

let infoCancel;
let availabilityCancel;

export default {
  namespaced: true,

  actions: {
    async make(_, { data, options }) {
      await client.post(`/api/transfer/make`, data, options);
    },

    async fetchInfo(_, data) {
      if (infoCancel) {
        infoCancel.cancel();
      }

      infoCancel = axios.CancelToken.source();

      return client.post(`/api/transfer/info`, data, {
        cancelToken: infoCancel.token,
      });
    },

    async fetchAvailability(_, params) {
      if (availabilityCancel) {
        availabilityCancel.cancel();
      }

      availabilityCancel = axios.CancelToken.source();

      return client.get(
        `/api/transfer/availability`,
        { params },
        { cancelToken: availabilityCancel.token }
      );
    },
  },
};
