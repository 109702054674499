import store from "@/store/store";

import { startInterval } from "@/utils/helpers";

import { WEBSOCKET_INTERVALS } from "@/constants";

let map = {};
let interval = null;

export default {
  start() {
    if (interval) {
      interval();
    }

    interval = startInterval(() => {
      const keys = Object.keys(map);

      if (!keys.length) {
        return;
      }

      store.dispatch("updateCollateral", map);
      map = {};
    }, WEBSOCKET_INTERVALS.BALANCE_UPDATE);
  },

  handler(payload) {
    map[payload.apiKeyId] = payload;
  },
};
