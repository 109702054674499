import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = ["width", "height"];
var _hoisted_2 = ["xlink:href"];
export default {
  __name: 'PBIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    w: {
      type: Number,
      "default": 24
    },
    h: {
      type: Number,
      "default": 24
    }
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("svg", {
        "class": "PBIcon",
        width: "".concat(__props.w, "px"),
        height: "".concat(__props.h, "px"),
        style: _normalizeStyle("min-width: ".concat(__props.w, "px; min-height: ").concat(__props.h, "px"))
      }, [_createElementVNode("use", {
        "xlink:href": "#".concat(__props.name)
      }, null, 8, _hoisted_2)], 12, _hoisted_1);
    };
  }
};