import client from "@/utils/axios";
import axios from "axios";

let cancelTokenSource;

export default {
  namespaced: true,

  actions: {
    async fetchInfo(_, params) {
      if (cancelTokenSource) {
        cancelTokenSource.cancel();
      }

      cancelTokenSource = axios.CancelToken.source();

      const {
        data: { fee, amountConverted },
      } = await client.get(
        `/api/wallet/withdraw/fee`,
        { params },
        { cancelToken: cancelTokenSource.token }
      );

      return { fee, amountConverted };
    },

    async make(_, params) {
      const { data } = await client.post(
        `/api/wallet/withdraw?apiKeyId=${params.apiKeyId}`,
        params
      );

      return data;
    },
  },
};
