import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-61a93912"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "ModalLoader fixed inset-0 z-[20000] flex items-center justify-center p-2xl overflow-hidden bg-secondary/60 backdrop-blur-sm"
};
export function render(_ctx, _cache) {
  var _component_PBLoader = _resolveComponent("PBLoader");
  return _openBlock(), _createBlock(_Teleport, {
    to: "#modals"
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_PBLoader)])]);
}