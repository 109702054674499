import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";

import { useStore } from 'vuex';
import { onBeforeUnmount } from 'vue';
export default {
  __name: 'index',
  setup: function setup(__props) {
    var store = useStore();
    onBeforeUnmount(function () {
      store.dispatch("newPortfolio/resetFilters");
      store.commit("setPortfolioParams", {});
    });
    return function (_ctx, _cache) {
      var _component_RouterView = _resolveComponent("RouterView");
      return _openBlock(), _createBlock(_component_RouterView);
    };
  }
};