import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, isRef as _isRef, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0,
  "class": "mb-xl"
};
var _hoisted_2 = {
  "class": "Section"
};
var _hoisted_3 = {
  "class": "bg-secondary pt-xl"
};
import { obscuredEmail } from "@/utils/helpers";

import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { ref, computed, watch } from 'vue';
export default {
  __name: 'Profile',
  setup: function setup(__props) {
    var store = useStore();
    var route = useRoute();
    var router = useRouter();
    var activeTab = ref("Security");
    var tabs = computed(function () {
      var result = [{
        label: "Security",
        route: "security"
      }];
      if (store.getters.hasPermission(store.state.config.userPermissions.MANAGE_PUBLIC_API_KEYS)) {
        result.push({
          label: "API Keys",
          route: "apikeys"
        });
      }
      return result;
    });
    function routerPush(selectedTab) {
      var tab = tabs.value.find(function (tab) {
        return selectedTab === tab.label;
      });
      if (tab.route === route.name) {
        return;
      }
      router.push({
        name: tab.route
      });
    }
    watch(route, function () {
      switch (route.name) {
        case "apikeys":
          activeTab.value = "API Keys";
          break;
        default:
          activeTab.value = "Security";
      }
    }, {
      immediate: true
    });
    return function (_ctx, _cache) {
      var _component_PBTab = _resolveComponent("PBTab");
      var _component_RouterView = _resolveComponent("RouterView");
      var _component_PBTabs = _resolveComponent("PBTabs");
      return _openBlock(), _createElementBlock("div", null, [_unref(store).state.auth.user ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_unref(obscuredEmail)(_unref(store).state.auth.user.email)), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [_createVNode(_component_PBTabs, {
        modelValue: _unref(activeTab),
        "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
          return _isRef(activeTab) ? activeTab.value = $event : null;
        }), routerPush]
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabs), function (tab, index) {
            return _openBlock(), _createBlock(_component_PBTab, {
              key: index,
              label: tab.label
            }, null, 8, ["label"]);
          }), 128)), _createElementVNode("div", _hoisted_3, [_createVNode(_component_RouterView)])];
        }),
        _: 1
      }, 8, ["modelValue"])])]);
    };
  }
};