import { formatCurrencyCasing, getRoundedDownValue } from "@/utils/helpers";
import { add } from "@/utils/math";

import { CURRENCIES } from "@/constants/currencies";

const USDT_CURRENCIES = ["USDT_OMNI", "USDT_ERC20", "USDT_TRC20"];

function mapXBTToBTC(item) {
  if (item.currency === "XBT") {
    return {
      ...item,
      currency: "BTC",
    };
  }
  return item;
}

function getInstrumentsByCurrency(currency) {
  const store = require("@/store/store");

  if (!currency) {
    return [];
  }

  return store.default.state.config.instruments.filter(
    (instrument) => instrument.quoteCurrency === currency
  );
}

function getEmptySpot(apiKeyId, currency, exchangeId) {
  const instruments = getInstrumentsByCurrency(currency);

  return instruments.map((instrument) => {
    return {
      exchangeId,
      apiKeyId,
      accountType: `${instrument.contractType}-${instrument.contract}`,
      currency: instrument.quoteCurrency,
      balance: 0,
    };
  });
}

function getBitfinexMarginApiKeys(currency) {
  const store = require("@/store/store");
  const state = store.default.state;
  const getters = store.default.getters;
  const { exchangeNames, getExchangeFullName, derivativesAccounts } = getters;
  const getApiKey = getters["portfolio/getApiKey"];

  return state.portfolio.activePortfolioApiKeys.reduce((memo, item) => {
    const existing = derivativesAccounts.find((account) => {
      return (
        account.apiKeyId === item.id &&
        account.currency === currency &&
        account.exchangeId === exchangeNames.BITFINEX &&
        account.accountType.includes("margin")
      );
    });

    const condition =
      item.exchangeId === exchangeNames.BITFINEX &&
      item?.restrictions?.margin_enabled &&
      !existing;

    if (condition) {
      const exchangeFullName = getExchangeFullName(item.exchangeId);
      const { name: apiKeyName } = getApiKey(item.id) ?? {};

      memo.push({
        exchangeId: item.exchangeId,
        apiKeyId: item.id,
        accountType: "margin-cross",
        apiKeyName,
        currency,
        total: 0,
        exchangeFullName,
      });
    }

    return memo;
  }, []);
}

function getBitfinexPerpetualApiKeys(currency) {
  const store = require("@/store/store");
  const state = store.default.state;
  const getters = store.default.getters;
  const getApiKey = getters["portfolio/getApiKey"];
  const getExchangeFullName = getters.getExchangeFullName;
  const exchangeNames = getters.exchangeNames;
  const derivativesAccounts = getters.derivativesAccounts;

  const result = state.portfolio.activePortfolioApiKeys.reduce((memo, item) => {
    const existing = derivativesAccounts.find((account) => {
      return (
        account.apiKeyId === item.id &&
        account.currency === currency &&
        account.exchangeId === exchangeNames.BITFINEX &&
        account.accountType.includes("perpetual")
      );
    });
    const condition =
      item.exchangeId === exchangeNames.BITFINEX &&
      item?.restrictions?.perpetuals_enabled &&
      !existing;

    if (condition) {
      const exchangeFullName = getExchangeFullName(item.exchangeId);
      const { name: apiKeyName } = getApiKey(item.id) || {};

      memo.push({
        exchangeId: item.exchangeId,
        apiKeyId: item.id,
        accountType: "perpetual",
        apiKeyName,
        currency,
        total: 0,
        exchangeFullName,
      });
    }

    return memo;
  }, []);

  return result;
}

function getGateUSDTEmptyApiKeys() {
  const store = require("@/store/store");
  const state = store.default.state;
  const getters = store.default.getters;
  const getApiKey = getters["portfolio/getApiKey"];
  const getExchangeFullName = getters.getExchangeFullName;
  const exchangeNames = getters.exchangeNames;
  const derivativesAccounts = getters.derivativesAccounts;

  function checkAccount(account, item, type) {
    return (
      account.apiKeyId === item.id &&
      account.currency === 'USDT' &&
      account.exchangeId === exchangeNames.GATE &&
      account.accountType.toLowerCase() === type
    );
  }

  function getEmptyAccount(item, accountType) {
    const exchangeFullName = getExchangeFullName(item.exchangeId);
    const { name: apiKeyName } = getApiKey(item.id) || {};

    return {
      exchangeId: item.exchangeId,
      apiKeyId: item.id,
      apiKeyName,
      currency: 'USDT',
      accountType,
      total: 0,
      exchangeFullName,
    };
  }

  const result = state.portfolio.activePortfolioApiKeys.reduce((memo, item) => {
    const futureUSDTMExisting = derivativesAccounts.find((account) => {
      return checkAccount(account, item, "future-usdt-m");
    });
    const perpetualUSDTMExisting = derivativesAccounts.find((account) => {
      return checkAccount(account, item, "perpetual-usdt-m");
    });
    const futureCoinMExisting = derivativesAccounts.find((account) => {
      return checkAccount(account, item, "future-coin-m");
    });
    const perpetualCoinMExisting = derivativesAccounts.find((account) => {
      return checkAccount(account, item, "perpetual-coin-m");
    });
    const optionExisting = derivativesAccounts.find((account) => {
      return checkAccount(account, item, "option");
    });

    if (item.exchangeId === exchangeNames.GATE) {
      if (!futureUSDTMExisting && item?.restrictions?.futures_enabled) {
        memo.push(getEmptyAccount(item, 'future-usdt-m'));
      }
      if (!perpetualUSDTMExisting && item?.restrictions?.perpetuals_enabled) {
        memo.push(getEmptyAccount(item, 'perpetual-usdt-m'));
      }
      if (!futureCoinMExisting && item?.restrictions?.futures_enabled) {
        memo.push(getEmptyAccount(item, 'future-coin-m'));
      }
      if (!perpetualCoinMExisting && item?.restrictions?.perpetuals_enabled) {
        memo.push(getEmptyAccount(item, 'perpetual-coin-m'));
      }
      if (!optionExisting && item?.restrictions?.options_enabled) {
        memo.push(getEmptyAccount(item, 'option'));
      }
    }

    return memo;
  }, []);

  return result;
}

function getGateBTCEmptyApiKeys() {
  const store = require("@/store/store");
  const state = store.default.state;
  const getters = store.default.getters;
  const getApiKey = getters["portfolio/getApiKey"];
  const getExchangeFullName = getters.getExchangeFullName;
  const exchangeNames = getters.exchangeNames;
  const derivativesAccounts = getters.derivativesAccounts;

  function checkAccount(account, item, type) {
    return (
      account.apiKeyId === item.id &&
      account.currency === 'BTC' &&
      account.exchangeId === exchangeNames.GATE &&
      account.accountType.toLowerCase() === type
    );
  }

  function getEmptyAccount(item, accountType) {
    const exchangeFullName = getExchangeFullName(item.exchangeId);
    const { name: apiKeyName } = getApiKey(item.id) || {};

    return {
      exchangeId: item.exchangeId,
      apiKeyId: item.id,
      apiKeyName,
      currency: 'BTC',
      accountType,
      total: 0,
      exchangeFullName,
    };
  }

  const result = state.portfolio.activePortfolioApiKeys.reduce((memo, item) => {
    const perpetualCoinMExisting = derivativesAccounts.find((account) => {
      return checkAccount(account, item, "perpetual-coin-m");
    });

    if (item.exchangeId === exchangeNames.GATE) {
      if (!perpetualCoinMExisting && item?.restrictions?.perpetuals_enabled) {
        memo.push(getEmptyAccount(item, 'perpetual-coin-m'));
      }
    }

    return memo;
  }, []);

  return result;
}

function getEmptyCollateral(apiKeyId, currency, exchangeId) {
  const store = require("@/store/store");

  const { derivativesCollateral } = store.default.state;

  const accounts = derivativesCollateral.filter(
    (account) => account.apiKeyId === apiKeyId
  );
  const contracts = accounts.map(({ accountType }) => accountType);
  const instruments = getInstrumentsByCurrency(currency);
  const emptyInstruments = instruments.filter(
    ({ contractType, contract }) =>
      !contracts.includes(`${contractType}-${contract}`)
  );

  return emptyInstruments.map((instrument) => {
    return {
      exchangeId,
      apiKeyId,
      accountType: `${instrument.contractType}-${instrument.contract}`,
      currency: instrument.quoteCurrency,
      balance: 0,
    };
  });
}

function getBitmexApiKeys(currency, collateral) {
  const store = require("@/store/store");
  const state = store.default.state;
  const getters = store.default.getters;
  const getApiKey = getters["portfolio/getApiKey"];
  const getExchangeFullName = getters.getExchangeFullName;

  return state.portfolio.activePortfolioApiKeys.reduce((memo, item) => {
    const currencies = ["USDT", "BTC", "APE", "MATIC"];
    const isExisting = collateral.find(
      (account) =>
        account.apiKeyId === item.id &&
        account.currency === currency &&
        account.exchangeId === "bitmex"
    );

    const condition =
      item.exchangeId === "bitmex" &&
      currencies.includes(currency) &&
      !isExisting;

    if (condition) {
      const exchangeFullName = getExchangeFullName(item.exchangeId);
      const { name: apiKeyName } = getApiKey(item.id) ?? {};

      memo.push({
        exchangeId: item.exchangeId,
        apiKeyId: item.id,
        accountType: "margin",
        apiKeyName,
        currency,
        total: 0,
        exchangeFullName,
      });
    }

    return memo;
  }, []);
}

function getMarginEmptyAccounts(spotOptions, currency, exchange) {
  const store = require("@/store/store");

  const { derivativesCollateral } = store.default.state;

  let result = [];

  const accounts = [...spotOptions, ...derivativesCollateral].filter(
    (item) => item.exchangeId === exchange
  );
  const mappedAccounts = [
    ...new Map(accounts.map((item) => [item.apiKeyId, item])).values(),
  ];

  for (const account of mappedAccounts) {
    if (account.accountType === "spot") {
      result = result.concat(
        getEmptySpot(account.apiKeyId, currency, exchange)
      );
    } else {
      result = result.concat(
        getEmptyCollateral(account.apiKeyId, currency, exchange)
      );
    }
  }

  return result.filter((item) => {
    const restrictions =
      store.default.state.portfolio.activePortfolio?.ApiKeys?.find((key) => {
        return item.exchangeId === key.exchangeId && item.apiKeyId === key.id;
      })?.restrictions;

    if (item.accountType.includes("margin-isolated")) {
      return restrictions?.margin_enabled;
    }

    if (item.accountType.includes("perpetual")) {
      return restrictions?.perpetuals_enabled;
    }

    if (item.accountType.includes("cash_settled")) {
      return restrictions?.futures_enabled;
    }

    return false;
  });
}

export function showRawMarginAccountType(option) {
  const store = require("@/store/store");
  const { exchangeNames } = store.default.getters;

  return (
    option.accountType.includes("margin-isolated") &&
    (option.exchangeId === exchangeNames.HITBTC ||
      option.exchangeId === exchangeNames.BEQUANT)
  );
}

export function showRawPerpetualAccountType(option) {
  const store = require("@/store/store");
  const { exchangeNames } = store.default.getters;

  return (
    option.accountType.includes("perpetual-") &&
    (option.exchangeId === exchangeNames.HITBTC ||
      option.exchangeId === exchangeNames.BEQUANT ||
      option.exchangeId === exchangeNames.BITFINEX)
  );
}

export function getGroupedOptions(options, currency) {
  const store = require("@/store/store");
  const { exchangeNames } = store.default.getters;

  let grouped = {};
  let result = [];

  for (const option of options) {
    const item = {
      ...option,
      uid: `${option.apiKeyId}-${option.accountType}`,
      searchBy: ["exchangeFullName", "apiKeyName", "exchangeId"],
    };
    if (!grouped[item.apiKeyId]) {
      grouped[item.apiKeyId] = {
        ...item,
        options: [],
        total: "0",
      };
    }
    grouped[item.apiKeyId].options.push(item);
  }

  for (const key in grouped) {
    const item = grouped[key];
    item.total = item.options.reduce((memo, current) => {
      const total = Math.max(current.total, 0);
      return add(memo, total);
    }, "0");
    item.total = getRoundedDownValue(item.total, item.currency);
  }

  for (const key in grouped) {
    if (grouped[key].options.length <= 1) {
      delete grouped[key].options;
    } else {
      grouped[key].options = grouped[key].options
        .map((option) => {
          option.nested = true;
          option.groupBy = true;
          option.searchBy = [
            "formattedAccountType",
            "exchangeId",
            "exchangeFullName",
          ];
          option.groupKey = "formattedAccountType";
          option.formattedAccountType = option.accountType;

          if (showRawPerpetualAccountType(option)) {
            option.formattedAccountType = "Perpetual";
            option.searchBy = ["accountType", "exchangeId", "exchangeFullName"];
          }

          if (option.exchangeId === exchangeNames.HUOBI) {
            const isUSDTM =
              option.accountType.toLowerCase() === "perpetuals-usdt-m";

            if (!isUSDTM) {
              option.formattedAccountType = option.accountType
                .replace(/-\w+$/i, "")
                .toLowerCase();
              option.searchBy = [
                "accountType",
                "exchangeId",
                "exchangeFullName",
              ];
            }
          }

          if (option.exchangeId === exchangeNames.OKEX) {
            option.formattedAccountType = option.accountType.match(/^\w+/i)[0];
            option.searchBy = ["accountType", "exchangeId", "exchangeFullName"];
          }

          if (showRawMarginAccountType(option)) {
            option.formattedAccountType = "Margin-isolated";
            option.searchBy = ["accountType", "exchangeId", "exchangeFullName"];
          }

          option.uid = `${option.apiKeyId}-${option.accountType}-nested`;
          option.total = getRoundedDownValue(option.total, option.currency);
          return option;
        })
        .filter((item) => {
          return !(
            item.exchangeId === exchangeNames.HUOBI &&
            (item.formattedAccountType === "perpetuals" ||
              item.accountType === "future") &&
            currency === CURRENCIES.USDT
          );
        });
    }

    result.push(grouped[key]);
  }

  return result;
}

export default function (
  currency,
  from = null,
  filterAccountsOptions = () => {}
) {
  if (!currency) {
    return [];
  }

  const store = require("@/store/store");
  const getters = store.default.getters;

  const { derivativesCollateral } = store.default.state;
  const { balance, exchangeNames, getExchangeFullName, currencyToAliasMap } =
    getters;

  let spotOptions = balance.map((item) => {
    let total = item.balance[currency];

    if (USDT_CURRENCIES.includes(currency)) {
      total = item.balance.USDT ?? total;
    }

    return {
      ...item,
      currency,
      accountType: "spot",
      total: total ?? 0,
    };
  });

  const hitbtcMarginEmptyAccounts = getMarginEmptyAccounts(
    spotOptions,
    currency,
    exchangeNames.HITBTC
  );

  const bequantMarginEmptyAccounts = getMarginEmptyAccounts(
    spotOptions,
    currency,
    exchangeNames.BEQUANT
  );

  const getApiKey = getters["portfolio/getApiKey"];

  const collateral = derivativesCollateral
    .filter(
      ({ exchangeId }) =>
        exchangeId !== exchangeNames.FTX && exchangeId !== exchangeNames.OKEX_V5
    )
    .concat(hitbtcMarginEmptyAccounts)
    .concat(bequantMarginEmptyAccounts)
    .map((item, index) => {
      const { exchangeId, currency, accountType, apiKeyId } = item;
      const exchangeFullName = getExchangeFullName(exchangeId);
      const total = parseFloat(item.balance);
      const { name: apiKeyName } = getApiKey(item.apiKeyId) ?? {};
      const aliasKey = `${exchangeId}:${currency?.toUpperCase()}`;
      const aliasCurrency = currencyToAliasMap[aliasKey];
      const formattedCurrency = aliasCurrency
        ? formatCurrencyCasing(aliasCurrency)
        : formatCurrencyCasing(currency);

      return {
        exchangeId,
        apiKeyId,
        accountType,
        apiKeyName,
        currency: formattedCurrency,
        total,
        exchangeFullName,
        label: index,
      };
    });

  spotOptions = spotOptions.concat(collateral);

  const bitmexData = collateral
    .filter((item) => item.exchangeId === exchangeNames.BITMEX)
    .map(mapXBTToBTC);

  let isBitmexExist = bitmexData.length >= 1;

  if (from) {
    isBitmexExist =
      bitmexData.filter((item) => filterAccountsOptions(item, "from")).length >=
        1 || from?.exchangeId === exchangeNames.BITMEX;
  }

  spotOptions = spotOptions
    .map(mapXBTToBTC)
    .concat(!isBitmexExist ? [] : getBitmexApiKeys(currency, collateral))
    .concat(getBitfinexMarginApiKeys(currency))
    .concat(getBitfinexPerpetualApiKeys(currency))
    .concat(getGateUSDTEmptyApiKeys())
    .concat(getGateBTCEmptyApiKeys())
    .filter((item) => {
      let formattedCurrency = currency;

      if (item.accountType !== "spot") {
        formattedCurrency = USDT_CURRENCIES.includes(formattedCurrency)
          ? "USDT"
          : formattedCurrency;
      }

      return (
        item.currency === formattedCurrency &&
        item.exchangeId !== exchangeNames.FTX
      );
    });

  if (from) {
    spotOptions = spotOptions.filter((item) =>
      filterAccountsOptions(item, "from")
    );
  }

  spotOptions = spotOptions
    .map((item, index) => {
      return {
        ...item,
        currency:
          USDT_CURRENCIES.includes(item.currency) &&
          ![exchangeNames.BINANCE_CUSTODY, exchangeNames.FIREBLOCKS].includes(
            item.exchangeId
          )
            ? "USDT"
            : item.currency,
        label: index,
      };
    })
    .sort(
      (
        { apiKeyName: apiKeyName1, exchangeId: exchangeId1 },
        { apiKeyName: apiKeyName2, exchangeId: exchangeId2 }
      ) => {
        const key1 = `${exchangeId1}:${apiKeyName1}`;
        const key2 = `${exchangeId2}:${apiKeyName2}`;

        return key1.localeCompare(key2);
      }
    );

  return getGroupedOptions(spotOptions);
}
