import { BigNumber } from "bignumber.js";

export function add(...args) {
  return args.reduce((memo, current) => {
    const currentBN = new BigNumber(current);
    const totalBN = new BigNumber(memo);

    return totalBN.plus(currentBN).valueOf();
  }, "0");
}

export function subtract(...args) {
  const numbers = [...args];
  numbers.shift();
  return numbers.reduce((memo, current) => {
    const currentBN = new BigNumber(current);
    const totalBN = new BigNumber(memo);

    return totalBN.minus(currentBN).valueOf();
  }, args[0]);
}

export function times(...args) {
  return args.reduce((memo, current) => {
    const currentBN = new BigNumber(current);
    const totalBN = new BigNumber(memo);

    return totalBN.times(currentBN).valueOf();
  }, "1");
}

export function isLessThan(first, second) {
  const firstBN = new BigNumber(first);
  const secondBN = new BigNumber(second);

  return firstBN.isLessThan(secondBN);
}

export function isGreaterThan(first, second) {
  const firstBN = new BigNumber(first);
  const secondBN = new BigNumber(second);

  return firstBN.isGreaterThan(secondBN);
}

export function isLessThanOrEqualTo(first, second) {
  const firstBN = new BigNumber(first);
  const secondBN = new BigNumber(second);

  return firstBN.isLessThanOrEqualTo(secondBN);
}

export function isGreaterThanOrEqualTo(first, second) {
  const firstBN = new BigNumber(first);
  const secondBN = new BigNumber(second);

  return firstBN.isGreaterThanOrEqualTo(secondBN);
}

export function isEqualTo(first, second) {
  const firstBN = new BigNumber(first);
  const secondBN = new BigNumber(second);

  return firstBN.isEqualTo(secondBN);
}

export function isZero(value = 0) {
  const valueBN = new BigNumber(value);

  return valueBN.isZero();
}

export function isNaN(value = 0) {
  const valueBN = new BigNumber(value);

  return valueBN.isNaN();
}

export function absolute(value = 0) {
  const valueBN = new BigNumber(value);

  return valueBN.abs();
}

export function negate(value) {
  const valueBN  = new BigNumber(value);
  
  return valueBN.negated().valueOf();
}