import { getFromLocalStorage, saveToLocalStorage } from "@/utils/helpers";
import client from "@/utils/axios";

import { USER_PERMISSIONS, UI_FORMATTERS } from "@/constants";

export default {
  namespaced: true,

  state: {
    activeDateRange: getFromLocalStorage("bq:active-date-range") ?? "1m",
    uiFormatters: { ...UI_FORMATTERS },
    userPermissions: USER_PERMISSIONS,
    notificationSeverityLevels: {},
    intermediaryCurrencies: [],
    treasuryRepaymentTypes: {},
    balanceHistoryPeriods: {},
    currenciesChainTypes: {},
    userRolePermissions: {},
    transferCurrencies: {},
    withdrawCurrencies: {},
    turnoverTimeFrames: [],
    notificationTypes: {},
    depositCurrencies: {},
    virtualExchanges: [],
    brokerTypes: {},
    instruments: [],
    currencies: [],
    exchanges: [],
    aliases: [],
  },

  actions: {
    async fetch({ commit }) {
      try {
        const { data } = await client.get("/api/v3/configs");

        commit("setConfig", data ?? {});
      } catch {
        // nothing
      }
    },
  },

  mutations: {
    setConfig(state, data) {
      state.currencies = data.currencies;
      state.depositCurrencies = data.depositCurrencies;
      state.transferCurrencies = data.transferCurrencies;
      state.aliases = data.aliases;
      state.userRolePermissions = data.userRolePermissions;
      state.withdrawCurrencies = data.withdrawCurrencies;
      state.uiFormatters = data.uiFormatters;
      state.balanceHistoryPeriods = data.balanceHistoryPeriods;
      state.brokerTypes = data.brokerTypes;
      state.exchanges = data.exchanges;
      state.instruments = data.instruments;
      state.intermediaryCurrencies = data.intermediaryCurrencies;
      state.currenciesChainTypes = data.currenciesChainTypes;
      state.virtualExchanges = data.virtualExchanges;
      state.turnoverTimeFrames = data.turnoverTimeFrames;
      state.treasuryRepaymentTypes = data.treasuryRepaymentTypes;
      state.notificationTypes = data.notificationTypes;
      state.notificationSeverityLevels = data.notificationSeverityLevels;
    },

    setActiveDateRange(state, data) {
      saveToLocalStorage("bq:active-date-range", data ?? "1m");
      state.activeDateRange = data;
    },
  },
};
