import client from "@/utils/axios";

export default {
  namespaced: true,

  state: {
    customerApiKeys: [],
    customerApiKeysView: {},
  },

  actions: {
    async fetchCustomerApiKeys({ commit }, id) {
      const { data } = await client.get(
        `/api/my/apikeys/${id}/customer-apikeys`
      );

      commit("setCustomerApiKeys", data);
    },

    async fetchCustomerApiKeysView({ commit }, { id, customerApiKeyId, code }) {
      const { data } = await client.post(
        `/api/my/apikeys/${id}/customer-apikeys/${customerApiKeyId}/view`,
        { code }
      );

      commit("setCustomerApiKeysView", data);
    },
  },

  mutations: {
    setCustomerApiKeys(state, customerApiKeys) {
      state.customerApiKeys = customerApiKeys;
    },

    setCustomerApiKeysView(state, customerApiKeysView) {
      state.customerApiKeysView = customerApiKeysView;
    },
  },
};
