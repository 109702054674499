import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.timers.js";
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, unref as _unref, isRef as _isRef, vModelDynamic as _vModelDynamic, withKeys as _withKeys, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderSlot as _renderSlot, vShow as _vShow } from "vue";

import { useSlots, ref, computed } from 'vue';
import { useVModel } from '@vueuse/core';
var _hoisted_1 = {
  "class": "PBInput relative"
};
var _hoisted_2 = {
  key: 0,
  "class": "flex items-center leading-none text-base text-white mb-sm cursor-default min-h-[16px]"
};
var _hoisted_3 = ["type", "placeholder", "disabled"];
var _hoisted_4 = {
  key: 1,
  "class": "absolute left-0 bottom-[-18px] px-[4px] pt-[4px] leading-[1.2] text-xs"
};
export default {
  __name: 'PBInput',
  props: {
    label: {
      type: String,
      "default": ""
    },
    placeholder: {
      type: String,
      "default": ""
    },
    error: {
      type: String,
      "default": ""
    },
    noBorder: Boolean,
    transparent: Boolean,
    disabled: Boolean,
    tooltip: {
      type: String,
      "default": ""
    },
    debounce: {
      type: Number,
      "default": 0
    },
    icon: {
      type: String,
      "default": ""
    },
    modelValue: {
      type: [String, Number],
      "default": null
    },
    type: {
      type: String,
      "default": "text"
    },
    inputProps: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  emits: ["update:modelValue", "input", "enter", "focus", "blur", "keyup"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var slots = useSlots();
    var appendRef = ref(null);
    var value = useVModel(props, "modelValue", emit);
    var paddingRight = computed(function () {
      if (slots.append && appendRef.value) {
        return "".concat(appendRef.value.offsetWidth + 24, "px");
      }
      return null;
    });
    var inputTimer;
    var keyupTimer;
    function handleInput(e) {
      if (props.debounce > 0) {
        clearTimeout(inputTimer);
        inputTimer = setTimeout(function () {
          return emit("input", e.target.value);
        }, props.debounce);
      } else {
        emit("input", e.target.value);
      }
    }
    function handleKeyup(e) {
      if (props.debounce > 0) {
        clearTimeout(keyupTimer);
        keyupTimer = setTimeout(function () {
          return emit("keyup", e.target.value);
        }, props.debounce);
      } else {
        emit("keyup", e.target.value);
      }
    }
    return function (_ctx, _cache) {
      var _component_PBIcon = _resolveComponent("PBIcon");
      var _component_Tooltip = _resolveComponent("Tooltip");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [__props.label ? (_openBlock(), _createElementBlock("label", _hoisted_2, [_createTextVNode(_toDisplayString(__props.label) + " ", 1), __props.tooltip ? (_openBlock(), _createBlock(_component_Tooltip, {
        key: 0,
        text: __props.tooltip,
        "class": "ml-md"
      }, {
        "default": _withCtx(function () {
          return [_createVNode(_component_PBIcon, {
            name: "info",
            w: 16,
            h: 16
          })];
        }),
        _: 1
      }, 8, ["text"])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createElementVNode("div", {
        "class": _normalizeClass(["relative", {
          'opacity-50 pointer-events-none': __props.disabled
        }])
      }, [_withDirectives(_createElementVNode("input", _mergeProps({
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _isRef(value) ? value.value = $event : null;
        }),
        type: __props.type,
        placeholder: __props.placeholder,
        disabled: __props.disabled,
        "class": ["peer w-full min-h-[54px] text-white text-base rounded-md transition-colors border border-solid font-normal", {
          'px-md': !_unref(slots).append && !__props.icon,
          'pl-[12px] pr-[54px]': _unref(slots).append,
          'pr-[12px] pl-[40px]': __props.icon,
          'border-zinc-500 focus:border-zinc-400': !__props.error && !__props.noBorder,
          'border-danger': __props.error,
          'border-transparent': __props.noBorder,
          'bg-transparent': __props.transparent,
          'bg-secondary': !__props.transparent
        }],
        style: {
          paddingRight: _unref(paddingRight)
        }
      }, __props.inputProps, {
        onInput: handleInput,
        onKeyup: [_cache[1] || (_cache[1] = _withKeys(function ($event) {
          return emit('enter');
        }, ["enter"])), handleKeyup],
        onFocus: _cache[2] || (_cache[2] = function ($event) {
          return emit('focus');
        }),
        onBlur: _cache[3] || (_cache[3] = function ($event) {
          return emit('blur');
        })
      }), null, 16, _hoisted_3), [[_vModelDynamic, _unref(value)]]), __props.icon ? (_openBlock(), _createBlock(_component_PBIcon, {
        key: 0,
        name: __props.icon,
        w: 18,
        h: 18,
        "class": _normalizeClass(["absolute z-[1] left-[12px] top-1/2 translate-y-[-50%] transition-colors", {
          'text-zinc-500 peer-focus:text-zinc-400': !__props.error,
          'text-danger': __props.error
        }])
      }, null, 8, ["name", "class"])) : _createCommentVNode("", true), _unref(slots).append ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        ref_key: "appendRef",
        ref: appendRef,
        "class": "absolute right-[12px] top-1/2 translate-y-[-50%]"
      }, [_renderSlot(_ctx.$slots, "append")], 512)) : _createCommentVNode("", true)], 2), !__props.error && _unref(slots).footer ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_renderSlot(_ctx.$slots, "footer")])) : _createCommentVNode("", true), _withDirectives(_createElementVNode("div", {
        "class": "absolute left-0 bottom-[-18px] px-[4px] pt-[4px] text-danger leading-[1.2] text-xs"
      }, _toDisplayString(__props.error), 513), [[_vShow, __props.error]])]);
    };
  }
};