import _objectSpread from "/app/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.array.concat.js";
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue";
var _hoisted_1 = {
  key: 0,
  "class": "DatePicker__main relative"
};
var _hoisted_2 = {
  key: 0,
  "class": "DatePicker__label"
};
var _hoisted_3 = {
  "class": "absolute flex"
};
var _hoisted_4 = {
  key: 1,
  "class": "DatePicker__no-inputs flex"
};
import datepicker from "js-datepicker";
import { formatDate } from "@/utils/helpers";
import "js-datepicker/dist/datepicker.min.css";

import { ref, reactive, computed, watch, onMounted } from 'vue';
import { onClickOutside } from '@vueuse/core';
export default {
  __name: 'DatePicker',
  props: {
    label: {
      type: String,
      "default": ""
    },
    calendar: Boolean,
    modelValue: {
      type: Object,
      "default": null
    },
    single: Boolean
  },
  emits: ["update:modelValue"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var options = {
      alwaysShow: true,
      onSelect: onSelect,
      maxDate: new Date(),
      customDays: ["S", "M", "T", "W", "T", "F", "S"]
    };
    var datepickerRef = ref(null);
    var firstInputRef = ref(null);
    var secondInputRef = ref(null);
    var isDropdown = ref(false);
    var dates = reactive({
      start: null,
      end: null
    });
    var singleInstance = ref(null);
    var singleDate = ref(null);
    var placeholder = computed(function () {
      if (props.single) {
        if (singleDate.value === null) {
          return "DD/MM/YYYY";
        }
        return formatDate(singleDate.value);
      }
      if (!dates.start || !dates.end) {
        return "DD/MM/YYYY - DD/MM/YYYY";
      }
      var range = dates.start.getRange();
      if (!range.start || !range.end) {
        return "DD/MM/YYYY - DD/MM/YYYY";
      }
      return "".concat(formatDate(range.start), " - ").concat(formatDate(range.end));
    });
    var isEmpty = computed(function () {
      return placeholder.value === "DD/MM/YYYY - DD/MM/YYYY" || placeholder.value === "DD/MM/YYYY";
    });
    function onSelect(_, date) {
      if (props.single) {
        emit("update:modelValue", date);
        return hideCalendar();
      }
      var range = dates.start.getRange();
      if (range.start && range.end) {
        emit("update:modelValue", range);
      }
    }
    function hideCalendar() {
      isDropdown.value = false;
    }
    function showCalendar() {
      isDropdown.value = true;
    }
    onClickOutside(datepickerRef, hideCalendar);
    watch(function () {
      return props.modelValue;
    }, function (value) {
      if (props.single) {
        return singleDate.value = value;
      }
      dates.start.setDate(value === null || value === void 0 ? void 0 : value.start);
      dates.end.setDate(value === null || value === void 0 ? void 0 : value.end);
    }, {
      deep: true
    });
    onMounted(function () {
      var id;
      try {
        id = crypto.randomUUID();
      } catch (_unused) {
        id = Math.ceil(Math.random() * 10000000000000000000);
      }
      if (props.single) {
        singleInstance.value = datepicker(firstInputRef.value, _objectSpread({
          id: id
        }, options));
        return;
      }
      dates.start = datepicker(firstInputRef.value, _objectSpread({
        id: id
      }, options));
      dates.end = datepicker(secondInputRef.value, _objectSpread({
        id: id
      }, options));
    });
    return function (_ctx, _cache) {
      var _component_PBIcon = _resolveComponent("PBIcon");
      return _openBlock(), _createElementBlock("div", {
        ref_key: "datepickerRef",
        ref: datepickerRef,
        "class": "DatePicker"
      }, [!__props.calendar ? (_openBlock(), _createElementBlock("div", _hoisted_1, [__props.label ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(__props.label), 1)) : _createCommentVNode("", true), _createElementVNode("div", {
        "class": _normalizeClass(["DatePicker__input", {
          empty: _unref(isEmpty)
        }]),
        onClick: showCalendar
      }, [_createTextVNode(_toDisplayString(_unref(placeholder)) + " ", 1), _createVNode(_component_PBIcon, {
        name: "calendar",
        "class": "ml-auto"
      })], 2), _withDirectives(_createElementVNode("div", _hoisted_3, [_createElementVNode("div", {
        ref_key: "firstInputRef",
        ref: firstInputRef
      }, null, 512), !__props.single ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "secondInputRef",
        ref: secondInputRef
      }, null, 512)) : _createCommentVNode("", true)], 512), [[_vShow, _unref(isDropdown)]])])) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", {
        ref_key: "firstInputRef",
        ref: firstInputRef
      }, null, 512), _createElementVNode("div", {
        ref_key: "secondInputRef",
        ref: secondInputRef
      }, null, 512)]))], 512);
    };
  }
};