import store from "@/store/store";
import { WEBSOCKET_INTERVALS } from "@/constants";

let trades = [];
let timeout = null;

export default (payload) => {
  clearTimeout(timeout);

  trades = [...payload, ...trades];

  if (!trades.length) {
    return;
  }

  timeout = setTimeout(() => {
    store.dispatch("trades/add", trades);
    trades = [];
  }, WEBSOCKET_INTERVALS.TRADES_DEBOUNCE);
};
