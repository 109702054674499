import _defineProperty from "/app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.string.small.js";
import { unref as _unref, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue";

import { computed } from 'vue';
export default {
  __name: 'PBButton',
  props: {
    disabled: Boolean,
    loading: Boolean,
    bordered: Boolean,
    href: {
      type: String,
      "default": ""
    },
    full: Boolean,
    transparent: Boolean,
    large: Boolean,
    small: Boolean,
    active: Boolean,
    color: {
      type: String,
      "default": ""
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var tag = computed(function () {
      return props.href ? "RouterLink" : "button";
    });
    var attrs = computed(function () {
      var attrs = {
        "class": _defineProperty({
          PBButton: true,
          loading: props.loading,
          bordered: props.bordered,
          full: props.full,
          transparent: props.transparent,
          large: props.large,
          small: props.small,
          active: props.active
        }, props.color, true),
        disabled: props.disabled
      };
      if (props.href) {
        attrs.to = props.href;
      }
      return attrs;
    });
    return function (_ctx, _cache) {
      var _component_PBSpinner = _resolveComponent("PBSpinner");
      return _openBlock(), _createBlock(_resolveDynamicComponent(_unref(tag)), _mergeProps(_unref(attrs), {
        "class": "rounded-md inline-flex items-center justify-center transition-all overflow-hidden font-semibold whitespace-nowrap"
      }), {
        "default": _withCtx(function () {
          return [!__props.loading ? _renderSlot(_ctx.$slots, "default", {
            key: 0
          }) : (_openBlock(), _createBlock(_component_PBSpinner, {
            key: 1,
            black: ""
          }))];
        }),
        _: 3
      }, 16);
    };
  }
};