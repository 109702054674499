import "core-js/modules/es.function.name.js";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue";

import { useSlots, computed } from 'vue';
export default {
  __name: 'PBStatus',
  props: {
    warning: Boolean,
    error: Boolean,
    note: Boolean,
    primaryText: {
      type: String,
      "default": ""
    },
    secondaryText: {
      type: String,
      "default": ""
    },
    href: {
      type: String,
      "default": ""
    },
    empty: Boolean,
    loading: Boolean,
    message: {
      type: String,
      required: true
    },
    width: {
      type: String,
      "default": "420px"
    }
  },
  emits: ["primary", "secondary"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var slots = useSlots();
    var icon = computed(function () {
      var icon = {
        name: "check",
        width: 16,
        height: 12
      };
      if (props.warning) {
        icon.name = "warning";
        icon.width = 24;
        icon.height = 21;
      }
      if (props.empty) {
        icon.name = "empty";
        icon.width = 20;
        icon.height = 20;
      }
      if (props.note) {
        icon.name = "note";
        icon.width = 20;
        icon.height = 20;
      }
      if (props.error) {
        icon.name = "close";
        icon.width = 12;
        icon.height = 12;
      }
      return icon;
    });
    return function (_ctx, _cache) {
      var _component_PBIcon = _resolveComponent("PBIcon");
      var _component_PBButton = _resolveComponent("PBButton");
      return _openBlock(), _createElementBlock("div", {
        "class": "PBStatus flex flex-col items-center justify-center w-full max-w-[420px] mx-auto text-white",
        style: _normalizeStyle({
          'max-width': __props.width
        })
      }, [_createElementVNode("div", {
        "class": _normalizeClass(["rounded-md flex items-center justify-center min-w-[48px] min-h-[48px]", {
          'text-success bg-success/40': !__props.error && !__props.warning && !__props.note,
          'text-danger bg-danger/40': __props.error,
          'text-primary bg-primary/40': __props.note || __props.warning
        }])
      }, [!_unref(slots)['custom-icon'] ? (_openBlock(), _createBlock(_component_PBIcon, {
        key: 0,
        name: _unref(icon).name,
        w: _unref(icon).width,
        h: _unref(icon).height
      }, null, 8, ["name", "w", "h"])) : _renderSlot(_ctx.$slots, "custom-icon", {
        key: 1
      })], 2), _createElementVNode("div", {
        "class": _normalizeClass(["text-2xl font-bold mt-xl mb-sm text-center", {
          'text-success': !__props.error && !__props.warning && !__props.note,
          'text-danger': __props.error,
          'text-primary': __props.note || __props.warning
        }])
      }, _toDisplayString(__props.message), 3), _createElementVNode("p", {
        "class": _normalizeClass({
          empty: __props.empty
        })
      }, [_renderSlot(_ctx.$slots, "default")], 2), !__props.href && !__props.empty ? (_openBlock(), _createBlock(_component_PBButton, {
        key: 0,
        "class": _normalizeClass({
          'mb-md': __props.secondaryText
        }),
        loading: __props.loading,
        onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
          return emit('primary');
        }, ["prevent"]))
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(__props.primaryText), 1)];
        }),
        _: 1
      }, 8, ["class", "loading"])) : !__props.empty ? (_openBlock(), _createBlock(_component_PBButton, {
        key: 1,
        "class": _normalizeClass({
          'mb-md': __props.secondaryText
        }),
        loading: __props.loading,
        href: __props.href
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(__props.primaryText), 1)];
        }),
        _: 1
      }, 8, ["class", "loading", "href"])) : _createCommentVNode("", true), __props.secondaryText ? (_openBlock(), _createElementBlock("a", {
        key: 2,
        "class": "transition-colors font-semibold text-primary hover:text-primary-hover",
        href: "#",
        onClick: _cache[1] || (_cache[1] = _withModifiers(function ($event) {
          return emit('secondary');
        }, ["prevent"]))
      }, _toDisplayString(__props.secondaryText), 1)) : _createCommentVNode("", true)], 4);
    };
  }
};