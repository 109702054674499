export function useDebounce() {
  let timeout;

  function debounce(cb, time) {
    clearTimeout(timeout);
    timeout = setTimeout(cb, time);
  }

  return {
    debounce,
  };
}
