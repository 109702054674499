import axios from "axios";

import { getFromLocalStorage } from "@/utils/helpers";

function requestInterceptor(request) {
  try {
    const token = getFromLocalStorage("bq:token");
    const portfolioId = getFromLocalStorage("bq:active-portfolio-id");
    request.headers.Authorization = token ? `Bearer ${token}` : "";
    request.headers["x-portfolio-id"] = portfolioId;
  } catch {
    // do nothing
  }
  return request;
}

const { VUE_APP_API_URL } = process.env;
const TIMEOUT = 60000;
const params = {
  baseURL: VUE_APP_API_URL,
  timeout: TIMEOUT,
  params: {},
};

const rawInstance = axios.create(params);
const mainInstance = axios.create(params);

rawInstance.interceptors.request.use(requestInterceptor, undefined);
mainInstance.interceptors.request.use(requestInterceptor, undefined);

export const raw = rawInstance;
export const main = mainInstance;
