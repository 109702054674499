import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, mergeProps as _mergeProps, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-d2013a60"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  key: 0,
  "class": "Popup__header rounded-t-md"
};
var _hoisted_2 = {
  "class": "Popup__content"
};
var _hoisted_3 = {
  key: 0,
  "class": "Popup__notification"
};
var _hoisted_4 = ["innerHTML"];
var _hoisted_5 = {
  key: 1,
  "class": "Popup__footer rounded-b-md"
};
var _hoisted_6 = {
  key: 1,
  "class": "ml-md"
};
import { useBodyScroll } from "@/composables/useBodyScroll";

import { useAttrs, useSlots, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
export default {
  __name: 'PBPopup',
  props: {
    title: {
      type: String,
      "default": ""
    },
    isOpen: Boolean,
    closeOnClickOutside: Boolean,
    notification: {
      type: String,
      "default": ""
    },
    header: {
      type: Boolean,
      "default": true
    },
    footer: {
      type: Boolean,
      "default": true
    },
    closeBtn: {
      type: Boolean,
      "default": true
    },
    headerCloseBtn: {
      type: Boolean,
      "default": true
    },
    notificationType: {
      type: String,
      "default": "danger"
    },
    notificationClosable: {
      type: Boolean,
      "default": true
    }
  },
  emits: ["close"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var attrs = useAttrs();
    var slots = useSlots();
    var windowRef = ref(null);
    var isNotification = ref(true);
    onClickOutside(windowRef, function () {
      if (props.closeOnClickOutside) {
        emit("close");
      }
    });
    useBodyScroll(function () {
      return props.isOpen;
    });
    return function (_ctx, _cache) {
      var _component_PBIcon = _resolveComponent("PBIcon");
      var _component_PBButton = _resolveComponent("PBButton");
      return _openBlock(), _createBlock(_Teleport, {
        to: "#modals"
      }, [props.isOpen ? (_openBlock(), _createElementBlock("div", _mergeProps({
        key: 0,
        "class": "Popup fixed inset-0 z-[20000] flex p-2xl backdrop-blur-sm"
      }, _unref(attrs)), [_createElementVNode("div", {
        ref_key: "windowRef",
        ref: windowRef,
        "class": _normalizeClass(["Popup__window shadow-lg border border-solid border-zinc-700 rounded-md", {
          'Popup__window--message': !__props.footer && !__props.header
        }]),
        style: _normalizeStyle({
          paddingTop: __props.header || _unref(slots).header ? '81px' : '0px',
          paddingBottom: __props.footer ? '81px' : '0px'
        })
      }, [__props.header || _unref(slots).header ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "header", {}, function () {
        return [_createTextVNode(_toDisplayString(__props.title), 1)];
      }), __props.headerCloseBtn ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        "class": "flex items-center rounded-md justify-center ml-auto min-w-[36px] min-h-[36px] border border-solid border-zinc-700 hover:bg-zinc-700 transition-colors",
        onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
          return emit('close');
        }, ["prevent"]))
      }, [_createVNode(_component_PBIcon, {
        name: "close",
        w: 12,
        h: 12
      })])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_2, [__props.notification && _unref(isNotification) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_PBIcon, {
        "class": _normalizeClass("text-".concat(__props.notificationType)),
        name: "warning",
        w: 24,
        h: 21
      }, null, 8, ["class"]), _createElementVNode("span", {
        innerHTML: __props.notification
      }, null, 8, _hoisted_4), __props.notificationClosable ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: "#",
        onClick: _cache[1] || (_cache[1] = _withModifiers(function ($event) {
          return isNotification.value = false;
        }, ["prevent"]))
      }, " Close ")) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "content")]), __props.footer ? (_openBlock(), _createElementBlock("div", _hoisted_5, [__props.closeBtn ? (_openBlock(), _createBlock(_component_PBButton, {
        key: 0,
        bordered: "",
        onClick: _cache[2] || (_cache[2] = _withModifiers(function ($event) {
          return emit('close');
        }, ["prevent"]))
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(" Close ")];
        }),
        _: 1
      })) : _createCommentVNode("", true), _unref(slots).footer ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_renderSlot(_ctx.$slots, "footer")])) : _createCommentVNode("", true)])) : _createCommentVNode("", true)], 6)], 16)) : _createCommentVNode("", true)]);
    };
  }
};