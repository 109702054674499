import client from "@/utils/axios";

export default {
  namespaced: true,

  state: {
    balanceSpot: null,
    balances: [],
    tokens: {
      AAVE: [],
      UniSwap: [],
    },
  },

  actions: {
    async updateBalance({ commit }) {
      try {
        const [spotBalances, balances] = await Promise.all([
          client.get(`/api/defi/my/balance/spot`),
          client.get(`/api/defi/my/custody/balances`),
        ]);
        commit("setBalanceSpot", spotBalances.data);
        commit("setBalances", balances.data);
      } catch {
        commit("setBalanceSpot", null);
        commit("setBalances", []);
      }
    },
  },

  getters: {
    fireblocksApiKey(_, __, rootState, rootGetters) {
      return rootState.portfolio.activePortfolio?.ApiKeys?.find(
        (a) =>
          a.exchangeId === rootGetters.exchangeNames.FIREBLOCKS &&
          !a.bankAccount
      )?.id;
    },

    fireblocksStatus(_, __, rootState, rootGetters) {
      return rootState.portfolio.activePortfolio?.ApiKeys?.find(
        (a) => a.exchangeId === rootGetters.exchangeNames.FIREBLOCKS
      )?.active;
    },

    defiAvailable(_, getters, rootState, rootGetters) {
      const { hasPermission } = rootGetters;

      return (
        getters.fireblocksApiKey &&
        (hasPermission(rootState.config.userPermissions.VIEW_DEFI) ||
          hasPermission(rootState.config.userPermissions.MANAGE_DEFI))
      );
    },
  },

  mutations: {
    setBalanceSpot(state, data) {
      state.balanceSpot = data;
    },

    setBalances(state, data) {
      state.balances = data;
    },

    setTokens(state, [key, val]) {
      if (!key || !val) {
        return;
      }
      state.tokens[key] = val;
    },
  },
};
