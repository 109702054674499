import store from "@/store/store";
import websocket from "@/lib/websocket";
import { WEBSOCKET_EVENTS } from "@/constants";
import { GLOBAL_EVENTS } from "@/constants/global-events";
import aggregatedParamsRemover from "./removers/aggregatedParams";
import aggregatedParamsUpdater from "./updaters/aggregatedParams";
import positionsRemover from "./removers/positions";
import positionsUpdater from "./updaters/positions";
import collateralUpdater from "./updaters/collateral";
import balanceUpdater from "./updaters/balance";
import createTransactionsHandler from "./creators/transactions";
import updateTransactionsHandler from "./updaters/transactions";
import tradesHandler from "./creators/trades";
import { getFromLocalStorage } from "@/utils/helpers";
import connectionBanner from "@/utils/connection-banner";
import eventBus from "@/utils/eventbus";

const { PING, SEND_ACTIVE_PORTFOLIO, SEND_TOKEN } = WEBSOCKET_EVENTS;

export const handleSubscriptionIdGenerated = () => {};

export const handlePong = () => {};

export const handleUnauthorized = () => {
  store.dispatch("auth/logout");
};

export const sendHeartbeat = () => {
  websocket.send({ event: PING });
  connectionBanner.show();
};

export const sendActivePortfolio = (id) => {
  if (!id && !store.state.portfolio.activePortfolioId) {
    return;
  }

  websocket.send({
    event: SEND_ACTIVE_PORTFOLIO,
    payload: {
      portfolioId: id ?? store.state.portfolio.activePortfolioId,
    },
  });
};

export const handleAuthorized = () => {
  sendActivePortfolio();
  eventBus.emit(GLOBAL_EVENTS.WEBSOCKET_CONNECTED);
};

export const sendJwtToken = () => {
  websocket.send({
    event: SEND_TOKEN,
    payload: {
      token: getFromLocalStorage("bq:token"),
    },
  });
};

export const handleAggregatedParamsRemoved = aggregatedParamsRemover.handler;
export const handleAggregatedParamsUpdated = aggregatedParamsUpdater.handler;

export const handlePositionsRemoved = positionsRemover.handler;
export const handlePositionsUpdated = positionsUpdater.handler;

export const handleCollateralUpdated = collateralUpdater.handler;

export const handleTransactionsCreated = createTransactionsHandler;
export const handleTransactionsUpdated = updateTransactionsHandler;
export const handleTradeCreated = tradesHandler;

export const handlePricesUpdated = () => {
  if (store.state.portfolioParams.apiKeyId) {
    store.commit("setPortfolioParams", { total: true });
  }
  store.dispatch("loadSpotBalance");
  store.dispatch("loadBalanceHistory");
};

export const handleBalancesUpdated = balanceUpdater.handler;

export const handleNotifications = (payload) => {
  store.dispatch(`notifications/addNewNotification`, payload);
};

export const handleOrderBookUpdated = (payload) => {
  store.commit("trading/setOrderBook", payload);
};

export const handleLastCandleUpdated = (payload) => {
  store.dispatch("trading/updateLastCandle", payload);
};

export const handleMarketTradesUpdated = (payload) => {
  store.dispatch("trading/updateMarketTrades", payload);
};

export const handleTradingOrderUpdated = (payload) => {
  store.dispatch("trading/updateTradingOrder", payload);
};
