import _toConsumableArray from "/app/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.filter.js";
var banners = ref([]);
export function useBanners() {
  function addBanner(data) {
    var banner = banners.value.find(function (banner) {
      return banner.type === data.type;
    });
    if (!banner) {
      var newBanners = _toConsumableArray(banners.value);
      newBanners.push(data);
      banners.value = newBanners;
    }
  }
  function removeBanner(data) {
    banners.value = banners.value.filter(function (banner) {
      return banner.type !== data.type;
    });
  }
  return {
    addBanner: addBanner,
    removeBanner: removeBanner,
    banners: banners
  };
}