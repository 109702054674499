import "core-js/modules/es.array.concat.js";
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  "class": "flex flex-col w-full h-full text-white"
};
var _hoisted_2 = {
  "class": "flex items-center justify-center flex-1"
};
var _hoisted_3 = ["innerHTML"];
var _hoisted_4 = ["innerHTML"];
import Header from "@/layouts/main-layout/Header.vue";
import Footer from "@/layouts/main-layout/Footer.vue";
import { USER_STATUS } from "@/constants";

import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
  __name: 'Verification',
  setup: function setup(__props) {
    var store = useStore();
    var message = computed(function () {
      if (store.state.auth.user.status === USER_STATUS.DISABLED) {
        return 'Unfortunately, your account has been disabled. To find out why your account was disabled or if you think this is a mistake, please contact <a href="mailto:support@bequant.io">support@bequant.io</a>.';
      }
      if (store.state.auth.user.status === USER_STATUS.PENDING_EMAIL_CONFIRMATION) {
        return "Before you can login, please confirm your email address.";
      }
      if (store.state.auth.user.status === USER_STATUS.PENDING_KYC) {
        if (localStorage.getItem("verification_in_progress") === "true") {
          return "Your account verification has not been completed. Click on the link below to complete the account verification process to enable your account.";
        }
        return "Your account is not active at present. Click on the link below to start the account verification process to enable your account.";
      }
      if (store.state.auth.user.status === USER_STATUS.KYC_REJECTED) {
        return "We appreciate your interest in BEQUANT Prime. Unfortunately, we have been unable to verify your account with our verification process. To verify your account, please contact <a href=\"mailto:support@bequant.io\">support@bequant.io</a>.";
      }
      return "";
    });
    var buttonTitle = computed(function () {
      if (store.state.auth.user.status === USER_STATUS.PENDING_KYC) {
        if (localStorage.getItem("verification_in_progress") === "true") {
          return "Complete verification";
        }
        return "Start verification";
      }
      return "";
    });
    var url = computed(function () {
      var _store$state$auth$use;
      return "".concat(process.env.VUE_APP_KYC_ACCOUNT_URL, "?uuid=").concat((_store$state$auth$use = store.state.auth.user) === null || _store$state$auth$use === void 0 ? void 0 : _store$state$auth$use.accountUuid);
    });
    function goToVerification() {
      localStorage.setItem("verification_in_progress", "true");
      window.location.href = url.value;
    }
    return function (_ctx, _cache) {
      var _component_PBStatus = _resolveComponent("PBStatus");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(Header, {
        empty: ""
      }), _createElementVNode("div", _hoisted_2, [_unref(store).state.auth.user.status !== _unref(USER_STATUS).KYC_REJECTED && _unref(store).state.auth.user.status !== _unref(USER_STATUS).PENDING_EMAIL_CONFIRMATION && _unref(store).state.auth.user.status !== _unref(USER_STATUS).DISABLED ? (_openBlock(), _createBlock(_component_PBStatus, {
        key: 0,
        warning: "",
        "primary-text": _unref(buttonTitle),
        message: "Dear client",
        onPrimary: goToVerification
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("span", {
            innerHTML: _unref(message)
          }, null, 8, _hoisted_3)];
        }),
        _: 1
      }, 8, ["primary-text"])) : (_openBlock(), _createBlock(_component_PBStatus, {
        key: 1,
        empty: "",
        warning: ""
      }, {
        "default": _withCtx(function () {
          return [_createElementVNode("span", {
            innerHTML: _unref(message)
          }, null, 8, _hoisted_4)];
        }),
        _: 1
      }))]), _createVNode(Footer, {
        empty: ""
      })]);
    };
  }
};