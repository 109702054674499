import store from "@/store/store";

import { startInterval } from "@/utils/helpers";

let map = {};
let interval = null;

export default {
  start() {
    if (interval) {
      interval();
    }

    const delay = localStorage.getItem("bq:balance-delay")
      ? parseInt(localStorage.getItem("bq:balance-delay"), 10)
      : 1000;

    interval = startInterval(() => {
      const keys = Object.keys(map);

      if (!keys.length) {
        return;
      }

      store.dispatch("updateCurrentBalance", map);
      map = {};
    }, delay);
  },

  handler(payload) {
    const {
      apiKeyId,
      balance,
      balanceConverted,
      totalConverted,
      available,
      availableConverted,
    } = payload;

    map[apiKeyId] = {
      balance,
      balanceConverted,
      available,
      availableConverted,
      totalConverted,
    };
  },
};
