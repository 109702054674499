import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  "class": "PBDropdown__list"
};
var _hoisted_2 = {
  "class": "PBDropdown__item"
};
var _hoisted_3 = {
  key: 0
};
var _hoisted_4 = ["onClick"];
var _hoisted_5 = {
  key: 1
};
var _hoisted_6 = ["onClick"];
var _hoisted_7 = {
  key: 2
};
var _hoisted_8 = ["onClick"];
var _hoisted_9 = {
  key: 3
};
var _hoisted_10 = ["href"];
import { obscuredEmail } from "@/utils/helpers";

import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
export default {
  __name: 'ProfileMenu',
  props: {
    empty: Boolean
  },
  setup: function setup(__props) {
    var store = useStore();
    var router = useRouter();
    var route = useRoute();
    function goTo(name, close) {
      if (route.name === name) {
        return;
      }
      router.push({
        name: name
      });
      close();
    }
    return function (_ctx, _cache) {
      var _component_PBIcon = _resolveComponent("PBIcon");
      var _component_PBDropdown = _resolveComponent("PBDropdown");
      return _openBlock(), _createBlock(_component_PBDropdown, {
        selectable: false,
        chevron: false,
        title: _unref(obscuredEmail)(_unref(store).state.auth.user.email),
        bordered: ""
      }, {
        icon: _withCtx(function () {
          return [_createVNode(_component_PBIcon, {
            name: "user",
            w: 16,
            h: 18
          })];
        }),
        menu: _withCtx(function (_ref) {
          var close = _ref.close;
          return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [!__props.empty ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_unref(store).getters.hasPermission(_unref(store).state.config.userPermissions.PAGES_SECURITY) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("a", {
            href: "#",
            onClick: _withModifiers(function ($event) {
              return goTo('security', close);
            }, ["prevent"])
          }, "Security", 8, _hoisted_4)])) : _createCommentVNode("", true), _unref(store).getters.hasPermission(_unref(store).state.config.userPermissions.PAGES_FEE_SCHEDULE) ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("a", {
            href: "#",
            onClick: _withModifiers(function ($event) {
              return goTo('fee-schedule', close);
            }, ["prevent"])
          }, "Fee Schedule", 8, _hoisted_6)])) : _createCommentVNode("", true), _unref(store).getters.hasPermission(_unref(store).state.config.userPermissions.PAGES_PUBLIC_API_KEYS) ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("a", {
            href: "#",
            onClick: _withModifiers(function ($event) {
              return goTo('apikeys', close);
            }, ["prevent"])
          }, "API Keys", 8, _hoisted_8)])) : _createCommentVNode("", true), _unref(store).state.auth.user.inRemidiation ? (_openBlock(), _createElementBlock("div", _hoisted_9, [_createElementVNode("a", {
            href: _unref(store).state.auth.user.remidiationLink
          }, "Verification", 8, _hoisted_10)])) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), _createElementVNode("div", null, [_createElementVNode("a", {
            href: "#",
            "class": "text-primary",
            onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
              return _unref(store).dispatch('auth/logout');
            }, ["prevent"]))
          }, "Logout")])])])];
        }),
        _: 1
      }, 8, ["title"]);
    };
  }
};