import dayjs from "dayjs";
import { useEventBus } from "@vueuse/core";

import client from "@/utils/axios";
import { notify } from "@/utils/notifications";

import { GLOBAL_EVENTS } from "@/constants/global-events";

const openNotificationsBus = useEventBus(GLOBAL_EVENTS.OPEN_NOTIFICATIONS);

export default {
  namespaced: true,

  state: {
    notifications: [],
    loaded: true,
  },

  actions: {
    async fetch({ commit }) {
      try {
        const { data } = await client.get("/api/notifications");

        commit("setNotifications", data || []);
      } catch {
        commit("setNotifications", []);
      }
    },

    async markRead(_, notificationIds) {
      try {
        await client.post(`/api/notifications/mark-read`, { notificationIds });
      } catch (e) {
        console.log("Failed to mark read notifications", e);
      }
    },

    addNewNotification({ state, commit, rootState }, notification) {
      const isSame = state.notifications.find(
        (item) => item.id === notification.id
      );

      if (isSame) {
        return;
      }

      state.notifications.unshift(notification);

      commit("setNotifications", state.notifications);

      let notifyOptions = {
        type: "info",
        text: "You received a new notification.",
      };

      const severity = notification.severityLevel;

      if (severity === rootState.config.notificationSeverityLevels.CRITICAL) {
        notifyOptions.persistent = true;
        notifyOptions.customAction = () => openNotificationsBus.emit();
        notifyOptions.text =
          "You received a new notification. Click here to open notifications.";
      }

      if (severity !== rootState.config.notificationSeverityLevels.INFO) {
        notify(notifyOptions);
      }
    },
  },

  getters: {
    formattedNotifications(state) {
      return state.notifications.map((notification) => {
        return {
          ...notification,
          date: dayjs(notification.timestamp).format("HH:mm:ss DD MMMM YYYY"),
        };
      });
    },

    newNotifications(_, getters) {
      return getters.formattedNotifications.filter(
        (notification) => !notification.isRead
      );
    },

    titlesMap(_, __, rootState) {
      return {
        [rootState.config.notificationTypes
          .TREASURY_INITIAL_PAYMENT_TRANSFER_ERROR]:
          "New deposit operation failed",
        [rootState.config.notificationTypes.TREASURY_DRAWDOWN_TRANSFER_ERROR]:
          "Add to deposit operation failed",
        [rootState.config.notificationTypes
          .TREASURY_REPAYMENT_TRANSFER_COMPLETED]: "Deposit operation complete",
      };
    },

    textsMap(_, __, rootState) {
      return {
        [rootState.config.notificationTypes
          .TREASURY_INITIAL_PAYMENT_TRANSFER_ERROR]:
          'New deposit operation {jobId} is failed. You can check the status <a class="text-primary" href="/earn/closed" data-href="/earn/closed">here</a>. For further details please contact Support.',
        [rootState.config.notificationTypes.TREASURY_DRAWDOWN_TRANSFER_ERROR]:
          'Add to deposit operation {jobId} is failed. You can check the status <a class="text-primary" href="/earn/history/{repaymentId}" data-href="/earn/history/{repaymentId}">here</a>. For further details please contact Support',
        [rootState.config.notificationTypes
          .TREASURY_REPAYMENT_TRANSFER_COMPLETED]:
          "Deposit operation {jobId} is complete.",
      };
    },
  },

  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
  },
};
