import store from "@/store/store";
import * as handlers from "@/lib/websocket/handlers";
import { WEBSOCKET_EVENTS } from "@/constants";
import connectionBanner from "@/utils/connection-banner";

const { VUE_APP_WEBSOCKET_URL } = process.env;

const HEARTBEAT_TIMEOUT = 10000;
const RECONNECT_TIMEOUT = 5000;

const {
  AUTHORIZE_SUCCESS,
  UNAUTHORIZED,
  SUBSCRIPTION_ID_GENERATED,
  BALANCES_UPDATED,
  PRICES_UPDATED,
  PONG,
  NOTIFICATION_CREATED,
  AGGREGATED_PARAMETER_REMOVED,
  AGGREGATED_PARAMETER_UPDATED,
  POSITION_REMOVED,
  POSITION_UPDATED,
  COLLATERAL_UPDATED,
  TRANSACTIONS_CREATED,
  TRANSACTIONS_UPDATED,
  TRADES_CREATED,
  TRADING_ORDERBOOK_UPDATED,
  TRADING_LAST_CANDLE_UPDATED,
  TRADING_TRADES_UPDATED,
  TRADING_ORDER_UPDATED,
} = WEBSOCKET_EVENTS;

const MESSAGE_HANDLERS = {
  [AUTHORIZE_SUCCESS]: "handleAuthorized",
  [UNAUTHORIZED]: "handleUnauthorized",
  [SUBSCRIPTION_ID_GENERATED]: "handleSubscriptionIdGenerated",
  [BALANCES_UPDATED]: "handleBalancesUpdated",
  [AGGREGATED_PARAMETER_REMOVED]: "handleAggregatedParamsRemoved",
  [AGGREGATED_PARAMETER_UPDATED]: "handleAggregatedParamsUpdated",
  [POSITION_REMOVED]: "handlePositionsRemoved",
  [POSITION_UPDATED]: "handlePositionsUpdated",
  [COLLATERAL_UPDATED]: "handleCollateralUpdated",
  [PRICES_UPDATED]: "handlePricesUpdated",
  [PONG]: "handlePong",
  [NOTIFICATION_CREATED]: "handleNotifications",
  [TRANSACTIONS_CREATED]: "handleTransactionsCreated",
  [TRANSACTIONS_UPDATED]: "handleTransactionsUpdated",
  [TRADES_CREATED]: "handleTradeCreated",

  [TRADING_ORDERBOOK_UPDATED]: "handleOrderBookUpdated",
  [TRADING_LAST_CANDLE_UPDATED]: "handleLastCandleUpdated",
  [TRADING_TRADES_UPDATED]: "handleMarketTradesUpdated",

  [TRADING_ORDER_UPDATED]: "handleTradingOrderUpdated",
};

let client;
let heartbeatTimeoutId;
let reconnectTimeoutId;

const handleOpen = () => {
  handlers.sendJwtToken();
  connectionBanner.resetAttempts();
  heartbeat();
};

function heartbeat() {
  heartbeatTimeoutId = setTimeout(() => {
    handlers.sendHeartbeat();
    heartbeat();
  }, HEARTBEAT_TIMEOUT);
}

function stopHeartbeat() {
  clearTimeout(heartbeatTimeoutId);
}

function stopReconnect() {
  clearTimeout(reconnectTimeoutId);
}

const terminate = () => {
  stopHeartbeat();
  stopReconnect();
};

const handleMessage = (e) => {
  let message;
  connectionBanner.hide();

  try {
    message = JSON.parse(e.data);
  } catch {
    // nothing
  }

  try {
    const { event, payload } = message;
    const handlerName = MESSAGE_HANDLERS[event];
    const handler = handlers[handlerName];

    if (!handler) {
      return;
    }

    handler(payload);
  } catch {
    // nothing
  }
};

const handleClose = (error) => {
  stopHeartbeat();

  switch (error.code) {
    case 1000:
    case 1005:
      connectionBanner.hide();
      break;
    default:
      if (store.state.auth.user) {
        connectionBanner.show(true);
        reconnectTimeoutId = setTimeout(() => {
          connectionBanner.incrementAttempts();
          connect();
        }, RECONNECT_TIMEOUT);
      }
      break;
  }
};

const close = () => {
  terminate();

  if (client && client.readyState !== WebSocket.CLOSED) {
    client.close();
  }
};

const handleError = () => {
  close();
};

const connect = () => {
  client = new WebSocket(VUE_APP_WEBSOCKET_URL);

  client.addEventListener("open", handleOpen);
  client.addEventListener("message", handleMessage);
  client.addEventListener("close", handleClose);
  client.addEventListener("error", handleError);
};

const send = (payload) => {
  if (!client) {
    return;
  }

  if (client.readyState === WebSocket.OPEN) {
    client.send(JSON.stringify(payload));
  }
};

export default {
  connect,
  close,
  send,
};
