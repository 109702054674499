import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-9804585c"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "PBSpinner relative w-[20px] h-[20px]"
};
export default {
  __name: 'PBSpinner',
  props: {
    white: {
      type: Boolean,
      "default": true
    },
    primary: Boolean,
    black: Boolean
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(12, function (n) {
        return _createElementVNode("div", {
          key: n,
          "class": _normalizeClass(["after:absolute after:top-0 after:left-[9px] after:w-[2px] after:h-[6px] rounded-[20%]", {
            'after:bg-white': __props.white && !__props.primary && !__props.black,
            'after:bg-primary': __props.primary,
            'after:bg-secondary': __props.black
          }])
        }, null, 2);
      }), 64))]);
    };
  }
};