import store from "@/store/store";

import { raw } from "@/utils/axiosInstances";
import { getFromLocalStorage } from "@/utils/helpers";

export default async (_, __, next) => {
  try {
    if (!getFromLocalStorage("bq:token")) {
      return next();
    }

    await raw.get("/api/v2/me");

    return next("/dashboard");
  } catch (e) {
    if (e?.response?.status === 403 && e?.response?.data?.code === 1) {
      store.commit("setRestricted", true);
    }
    return next();
  }
};
