import websocket from "@/lib/websocket";

import { formatUserResponse } from "@/utils/formatters";
import { saveToLocalStorage, getFromLocalStorage } from "@/utils/helpers";
import client from "@/utils/axios";

export default {
  namespaced: true,

  state: {
    user: null,
  },

  actions: {
    async register({ commit }, payload) {
      const { data } = await client.post("/api/v2/register", payload);

      commit("setUser", formatUserResponse(data));
    },

    async confirmRegistration(_, token) {
      return client.post("/api/confirm", { token });
    },

    async login({ commit }, data) {
      const {
        data: { token },
      } = await client.post("/api/v2/login", data);

      const { data: userData } = JSON.parse(atob(token.split(".")[1]));
      const { status, accountUuid, twoFaEnabled, forcePasswordChange } =
        userData;

      saveToLocalStorage("bq:token", token);

      if (!twoFaEnabled) {
        commit("setUser", formatUserResponse(userData));
      } else {
        commit("setUser", {
          twoFaEnabled,
          status,
          accountUuid,
          forcePasswordChange,
        });
      }
    },

    async login2fa(_, data) {
      try {
        const {
          data: { token },
        } = await client.post("/api/2fa/login", data);

        saveToLocalStorage("bq:token", token);
      } catch (e) {
        websocket.close();
        throw e;
      }
    },

    async verify2fa(_, code) {
      await client.post("/api/2fa/verify-code", { code });
    },

    async logout() {
      websocket.close();

      try {
        window.Intercom("shutdown");
      } catch {
        // nothing
      }

      if (getFromLocalStorage("bq:token")) {
        localStorage.clear();
        window.location.reload();
      } else {
        localStorage.clear();
      }
    },

    async fetch2faQRCode() {
      const { data } = await client.get("/api/2fa/qr-code");
      return data;
    },

    async enable2fa(_, code) {
      return client.post("/api/2fa/confirm", { code });
    },

    async disable2fa(_, code) {
      return client.post("/api/2fa/reset", { code });
    },

    async changePassword(_, payload) {
      await client.post("/api/users/change-password", payload);
    },

    async fetchUser({ commit }) {
      try {
        const { data } = await client.get("/api/v2/me");

        if (!data) {
          throw new Error("Not Authenticated");
        }

        websocket.connect();

        commit("setUser", formatUserResponse(data));
      } catch {
        websocket.close();
        throw new Error("Unauthorized");
      }
    },
  },

  mutations: {
    setUser(state, data) {
      state.user = data;
    },
  },
};
