import client from "@/utils/axios";

import { useCurrency } from "@/composables/useCurrency";

import { add } from "@/utils/math";

export default {
  namespaced: true,

  state: {
    loans: [],
    options: [],
    total: {},
  },

  actions: {
    async fetchLoans({ commit }) {
      try {
        const params = {
          productType: "Loan",
        };

        const { data } = await client.get("/api/treasury/transactions", {
          params,
        });

        commit("setLoansAndTotals", data);
      } catch (error) {
        commit("setLoansAndTotals", {});
      }
    },

    async fetchLoansOptions({ commit }) {
      try {
        const params = {
          productType: "Loan",
        };

        const { data } = await client.get("/api/treasury/tariffs", { params });

        commit("setLoansOptions", data || []);
      } catch (error) {
        commit("setLoansOptions", []);
      }
    },

    async requestLoan(_, data) {
      await client.post(`/api/my/loans`, data);
    },
  },

  getters: {
    loans(state, _, __, rootGetters) {
      const { getPropertyValue } = rootGetters;
      const { convertTo } = useCurrency();

      return state.loans.map((loan) => {
        return {
          id: loan.id,
          currency: loan.currency,
          value: getPropertyValue(loan.amount, true, null, null, loan.currency),
          convertedValue: getPropertyValue(
            loan.amountConverted[convertTo.value]
          ),
          totalAmount: loan.totalAmount,
          totalAmountConverted: getPropertyValue(
            loan.totalAmountConverted[convertTo.value]
          ),
          issueDate: new Date(loan.dateFrom),
          dateFromTimestamp: new Date(loan.dateFrom).getTime(),
          maturityDate: loan.dateTo ? new Date(loan.dateTo) : null,
          dateToTimestamp: loan.dateTo ? new Date(loan.dateTo).getTime() : 0,
          interestRate: parseFloat(loan.rate),
          accruedInterest: getPropertyValue(
            loan?.accruedInterest,
            null,
            loan.currency
          ),
          convertedAccruedInterest: getPropertyValue(
            loan?.accruedInterestConverted?.[convertTo.value],
            null,
            loan.currency
          ),
          isActive: loan.isActive,
        };
      });
    },

    totalLoans(state, _, __, rootGetters) {
      const { convertTo } = useCurrency();

      return rootGetters.getPropertyValue(state.total[convertTo.value]);
    },

    loansTransactionsTotal(_, getters) {
      const { loans } = getters;

      return loans
        .filter(({ isActive }) => isActive)
        .reduce((memo, item) => {
          const { currency, totalAmount, totalAmountConverted } = item;
          memo.total[currency] = add(memo.total[currency] ?? 0, totalAmount);
          memo.totalConverted[currency] = add(memo.totalConverted[currency] ?? 0, totalAmountConverted);

          return memo;
        }, { total: {}, totalConverted: {} });
    },

    totalAccruedInterest(_, getters) {
      return getters.loans.reduce((memo, current) => {
        memo = add(memo, current.convertedAccruedInterest);
        return memo;
      }, "0");
    },

    currencies(state, _, __, rootGetters) {
      const { getCurrencyFullName } = rootGetters;

      return state.options.map((item) => {
        return {
          text: getCurrencyFullName(item.currency),
          value: item.currency,
        };
      });
    },
  },

  mutations: {
    setLoansAndTotals(state, data) {
      state.loans = data?.transactions ?? [];
      state.total = data?.total ?? {};
    },

    setLoansOptions(state, data) {
      state.options = data;
    },
  },
};
