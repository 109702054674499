import client from "@/utils/axios";

export default {
  namespaced: true,

  state: {
    addresses: [],
    currencies: {},
  },

  actions: {
    async getWhitelistAddresses({ commit }, apiKeyId) {
      try {
        const params = {
          apiKeyId,
        };

        const { data } = await client.get("/api/wallet/withdraw/addresses", {
          params,
        });

        commit("setWhitelistAddresses", data);
      } catch {
        commit("setWhitelistAddresses", []);
      }
    },

    async createWhitelistAddress(_, form) {
      const { data } = await client.post(
        `/api/wallet/withdraw/addresses`,
        form
      );

      return data;
    },

    async deleteWhitelistAddress(_, { addressId, apiKeyId }) {
      const params = {
        apiKeyId,
      };

      const { data } = await client.delete(
        `/api/wallet/withdraw/addresses/${addressId}`,
        { params }
      );

      return data;
    },
  },

  mutations: {
    setWhitelistAddresses(state, addresses) {
      state.addresses = addresses;
    },
  },
};
