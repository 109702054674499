import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.function.name.js";
import store from "@/store/store";
import { USER_PERMISSIONS } from "../constants";
import balanceUpdater from "../lib/websocket/updaters/balance";
import isAuthenticated from "./middlewares/is-authenticated";
import beforeLoginPage from "./middlewares/before-login-page";
import canAccessPage from "./middlewares/can-access-page";
import MainLayout from "@/layouts/main-layout/MainLayout.vue";
import PublicLayout from "@/layouts/PublicLayout.vue";
import Earnings from "@/pages/earnings/Earnings.vue";
import Accounts from "@/pages/accounts/index.vue";
import NewPortfolioIndex from "@/pages/new-portfolio/index.vue";
import Profile from "@/pages/profile/Profile.vue";
import Defi from "@/pages/defi/Defi.vue";
var router = createRouter({
  history: createWebHistory(),
  routes: [{
    path: "/login",
    name: "login",
    beforeEnter: beforeLoginPage,
    component: function component() {
      return import("@/layouts/AuthLayout.vue");
    }
  }, {
    path: "/register",
    name: "register",
    beforeEnter: beforeLoginPage,
    component: function component() {
      return import("@/layouts/AuthLayout.vue");
    }
  }, {
    path: "/confirm/:token?",
    name: "confirm-registration",
    beforeEnter: beforeLoginPage,
    component: function component() {
      return import("@/layouts/AuthLayout.vue");
    }
  }, {
    path: "/outages",
    component: PublicLayout,
    children: [{
      path: "/outages",
      name: "outages",
      component: function component() {
        return import("@/pages/public/Outages.vue");
      }
    }]
  }, {
    path: "/",
    component: MainLayout,
    beforeEnter: isAuthenticated,
    children: [{
      path: "",
      redirect: "/dashboard"
    }, {
      path: "/dashboard",
      name: "dashboard",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_DASHBOARD),
      component: function component() {
        return import("@/pages/dashboard/Dashboard.vue");
      },
      meta: {
        title: "Dashboard"
      }
    }, {
      path: "trades",
      name: "trades",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_TRADES),
      component: function component() {
        return import("@/pages/trades/Trades.vue");
      },
      meta: {
        title: "Trades"
      }
    }, {
      path: "transactions",
      name: "transactions",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_TRANSACTIONS),
      component: function component() {
        return import("@/pages/transactions/Transactions.vue");
      },
      meta: {
        title: "Transactions"
      }
    }, {
      path: "portfolio",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_PORTFOLIO),
      component: NewPortfolioIndex,
      children: [{
        path: "",
        name: "portfolio",
        component: function component() {
          return import("@/pages/new-portfolio/Portfolio.vue");
        },
        meta: {
          title: "Portfolio"
        }
      }, {
        path: "exchange-margin",
        name: "exchange-margin",
        component: function component() {
          return import("@/pages/new-portfolio/ExchangeMargin.vue");
        },
        meta: {
          title: "Exchange Margin",
          back: true
        }
      }, {
        path: "open-positions",
        name: "open-positions",
        component: function component() {
          return import("@/pages/new-portfolio/OpenPositions.vue");
        },
        meta: {
          title: "Open Positions",
          back: true
        }
      }, {
        path: "exposure",
        name: "exposure",
        component: function component() {
          return import("@/pages/new-portfolio/Exposure.vue");
        },
        meta: {
          title: "Exposure",
          back: true,
          beta: true
        }
      }]
    }, {
      path: "loans",
      name: "loans",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_LOANS),
      component: function component() {
        return import("@/pages/loans/Loans.vue");
      },
      meta: {
        title: "Loans"
      }
    }, {
      path: "/loans/history/:id",
      name: "loans-history",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_LOANS),
      component: function component() {
        return import("@/pages/loans/History.vue");
      },
      meta: {
        title: "Transaction History",
        back: true
      }
    }, {
      path: "notifications",
      name: "notifications",
      component: function component() {
        return import("@/pages/notifications/Notifications.vue");
      },
      meta: {
        title: "Notifications"
      }
    }, {
      path: "earn",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_EARN),
      component: Earnings,
      children: [{
        path: "",
        name: "earn",
        component: function component() {
          return import("@/pages/earnings/tabs/Options.vue");
        },
        meta: {
          title: "Earn"
        }
      }, {
        path: "active",
        name: "active-deposits",
        component: function component() {
          return import("@/pages/earnings/tabs/Active.vue");
        },
        meta: {
          title: "Earn"
        }
      }, {
        path: "closed",
        name: "closed-deposits",
        component: function component() {
          return import("@/pages/earnings/tabs/Closed.vue");
        },
        meta: {
          title: "Earn"
        }
      }, {
        path: "history/:id",
        name: "history-deposits",
        component: function component() {
          return import("@/pages/earnings/History.vue");
        },
        meta: {
          title: "Transaction History",
          back: true
        }
      }]
    }, {
      path: "accounts",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_ACCOUNTS),
      component: Accounts,
      meta: {
        title: "Accounts"
      },
      children: [{
        path: "",
        name: "list",
        component: function component() {
          return import("@/pages/accounts/Accounts.vue");
        },
        meta: {
          title: "Accounts"
        }
      }, {
        path: "/accounts/:id/api-keys",
        name: "account-apikeys",
        component: function component() {
          return import("@/pages/accounts/ApiKeys.vue");
        },
        meta: {
          title: "Api Keys"
        }
      }, {
        path: "/accounts/:id/whitelist",
        name: "whitelist",
        component: function component() {
          return import("@/pages/accounts/Whitelist.vue");
        },
        meta: {
          title: "Whitelist"
        }
      }]
    }, {
      path: "turnover",
      name: "turnover",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_FEES_TURNOVER),
      component: function component() {
        return import("@/pages/reports/Turnover.vue");
      },
      meta: {
        title: "Fees & Turnover Report"
      }
    }, {
      path: "pnl",
      name: "pnl",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_PNL),
      component: function component() {
        return import("@/pages/reports/PNL.vue");
      },
      meta: {
        title: "Profit & Loss Report",
        beta: true
      }
    }, {
      path: "trading",
      name: "trading",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_EXCHANGE),
      component: function component() {
        return import("@/pages/trading/Trading.vue");
      },
      meta: {
        title: "Trading"
      }
    }, {
      path: "currencies",
      name: "currencies",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_CURRENCIES_AVAILABILITY),
      component: function component() {
        return import("@/pages/currencies/index.vue");
      },
      meta: {
        title: "Currencies Availability"
      }
    }, {
      path: "otc",
      name: "otc",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_OTC),
      component: function component() {
        return import("@/pages/otc-form/OTC.vue");
      },
      meta: {
        title: "OTC"
      }
    }, {
      path: "orders-and-positions",
      name: "ordersAndPositions",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_OPEN_ORDERS_POSITIONS),
      component: function component() {
        return import("@/pages/orders-and-positions/OrdersAndPositions.vue");
      },
      meta: {
        title: "Open Orders and Positions"
      }
    }, {
      path: "profile",
      component: Profile,
      children: [{
        path: "",
        name: "security",
        beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_SECURITY),
        component: function component() {
          return import("@/pages/profile/tabs/Security.vue");
        },
        meta: {
          title: "Profile"
        }
      }, {
        path: "apikeys",
        name: "apikeys",
        beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_PUBLIC_API_KEYS),
        component: function component() {
          return import("@/pages/profile/tabs/ApiKeys.vue");
        },
        meta: {
          title: "Profile"
        }
      }]
    }, {
      path: "fee-schedule",
      name: "fee-schedule",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_FEE_SCHEDULE),
      component: function component() {
        return import("@/pages/profile/tabs/FeeSchedule.vue");
      },
      meta: {
        title: "Fee Schedule"
      }
    }, {
      path: "defi",
      beforeEnter: canAccessPage(USER_PERMISSIONS.PAGES_DEFI),
      component: Defi,
      children: [{
        path: "",
        name: "defi",
        component: function component() {
          return import("@/pages/defi/DefiIndex.vue");
        },
        meta: {
          title: "DeFi"
        }
      }, {
        path: "aave",
        name: "aave",
        component: function component() {
          return import("@/pages/defi/DefiAAVE.vue");
        },
        meta: {
          title: "DeFi AAVE",
          backUrl: "/defi"
        }
      }]
    }, {
      path: "/:pathMatch(.*)*",
      component: function component() {
        return import("@/pages/error/404.vue");
      },
      meta: {
        page: "not-found"
      }
    }]
  }]
});
router.beforeEach(function (to, _, next) {
  if (to.name === "trading") {
    localStorage.setItem("bq:balance-delay", 1000);
    balanceUpdater.start();
  } else {
    var delay = localStorage.getItem("bq:balance-delay");
    if (delay !== "10000") {
      localStorage.setItem("bq:balance-delay", 10000);
      balanceUpdater.start();
    }
  }
  store.commit("setRoute", to);
  next();
});
export default router;