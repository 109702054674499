import { sendActivePortfolio } from "@/lib/websocket/handlers";

import { getFromLocalStorage, saveToLocalStorage } from "@/utils/helpers";
import client from "@/utils/axios";

export default {
  namespaced: true,

  state: {
    portfolios: [],
    activePortfolio: {},
    activePortfolioId: null,
    apiKeyIdToApiKeyMap: {},
    activePortfolioApiKeys: [],
    linkedAccountsMap: {},
    swappedAccountsMap: {},
  },

  actions: {
    async fetchPortfolios({ commit, dispatch }) {
      try {
        const { data } = await client.get("/api/my/portfolios");

        commit("setPortfolios", data);

        dispatch("findAndSetActivePortfolio");
      } catch {
        // do nothing
      }
    },

    findAndSetActivePortfolio({ state, commit }) {
      const { portfolios } = state;

      if (portfolios?.length) {
        const currentPortfolioId = getFromLocalStorage(
          "bq:active-portfolio-id"
        );
        const currentPortfolio = portfolios.find(
          (portfolio) => portfolio.id === currentPortfolioId
        );
        const activePortfolioId = currentPortfolio
          ? currentPortfolio.id
          : portfolios[0].id;

        commit("setActivePortfolio", activePortfolioId);
      }
    },

    async fetchLinkedAccount({ commit }) {
      try {
        const { data } = await client.get(
          "/api/custody/linked-accounts-mappings"
        );

        commit("setLinkedAccountsMap", data ?? {});
      } catch {
        commit("setLinkedAccountsMap", {});
      }
    },
  },

  getters: {
    getApiKey: (state) => (apiKeyId) => {
      return state.apiKeyIdToApiKeyMap[apiKeyId];
    },

    getLinkedAccount: (state) => (selectedAccountID) => {
      const linkedAccountID = state.linkedAccountsMap[selectedAccountID];

      if (!linkedAccountID) {
        return null;
      }

      return state.activePortfolio?.ApiKeys?.find(
        (item) => item.id === linkedAccountID
      );
    },

    getReversedLinkedAccount: (state) => (selectedAccountID) => {
      const linkedAccountID = state.swappedAccountsMap[selectedAccountID];

      if (!linkedAccountID) {
        return null;
      }

      return state.activePortfolio?.ApiKeys?.find(
        (item) => item.id === linkedAccountID
      );
    },
  },

  mutations: {
    setPortfolios(state, data) {
      state.portfolios = data;
      state.apiKeyIdToApiKeyMap = state.portfolios.reduce((memo, { ApiKeys }) => {
        for (let apiKey of ApiKeys)  {
          const { id } = apiKey;
          memo[id] = apiKey
        }
        return memo;
      }, {});
    },

    setActivePortfolio(state, data) {
      saveToLocalStorage("bq:active-portfolio-id", data);
      state.activePortfolioId = data;
      state.activePortfolio = state.portfolios.find(
        ({ id }) => id === state.activePortfolioId
      );
      state.activePortfolioApiKeys = state.activePortfolio?.ApiKeys ?? [];
      sendActivePortfolio(data);
    },

    setLinkedAccountsMap(state, data) {
      state.linkedAccountsMap = data;
      state.swappedAccountsMap = Object.fromEntries(
        Object.entries(data).map((item) => item.reverse())
      );
    },
  },
};
