import aggregatedParamsRemover from "./removers/aggregatedParams";
import aggregatedParamsUpdater from "./updaters/aggregatedParams";
import positionRemover from "./removers/positions";
import positionUpdater from "./updaters/positions";
import collateralUpdater from "./updaters/collateral";
import balanceUpdater from "./updaters/balance";

export default {
  start() {
    aggregatedParamsRemover.start();
    aggregatedParamsUpdater.start();
    positionRemover.start();
    positionUpdater.start();
    collateralUpdater.start();
    balanceUpdater.start();
  },
};
