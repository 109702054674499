import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  id: "app"
};
import Restricted from "@/pages/auth/Restricted.vue";
import Verification from "@/pages/auth/Verification.vue";
import { USER_STATUS } from "@/constants";

import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
  __name: 'App',
  setup: function setup(__props) {
    var store = useStore();
    var needsVerification = computed(function () {
      var _store$state$auth$use, _store$state$auth$use2;
      return ((_store$state$auth$use = store.state.auth.user) === null || _store$state$auth$use === void 0 ? void 0 : _store$state$auth$use.status) && ((_store$state$auth$use2 = store.state.auth.user) === null || _store$state$auth$use2 === void 0 ? void 0 : _store$state$auth$use2.status) !== USER_STATUS.ACTIVE;
    });
    return function (_ctx, _cache) {
      var _component_PBSnackbar = _resolveComponent("PBSnackbar");
      var _component_CryptoIcons = _resolveComponent("CryptoIcons");
      var _component_PBIcons = _resolveComponent("PBIcons");
      var _component_RouterView = _resolveComponent("RouterView");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_PBSnackbar), _createVNode(_component_CryptoIcons), _createVNode(_component_PBIcons), _unref(store).state.isRestricted ? (_openBlock(), _createBlock(Restricted, {
        key: 0
      })) : _unref(needsVerification) ? (_openBlock(), _createBlock(Verification, {
        key: 1
      })) : (_openBlock(), _createBlock(_component_RouterView, {
        key: 2
      }))]);
    };
  }
};