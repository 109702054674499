function mounted(el, binding, vnode) {
  const { self = false } = binding.modifiers || {};
  const value = binding.value;
  const options = (typeof value === "object" && value.options) || {
    passive: true,
  };
  const handler =
    typeof value === "function" || "handleEvent" in value
      ? value
      : value.handler;

  const target = self
    ? el
    : binding.arg
    ? document.querySelector(binding.arg)
    : window;

  if (!target) {
    return;
  }

  target.addEventListener("scroll", handler, options);

  el._onScroll = Object(el._onScroll);

  const uid = vnode.context ? vnode.context._uid : null;

  el._onScroll[uid] = {
    handler,
    options,
    target: self ? undefined : target,
  };
}

function unmounted(el, _, vnode) {
  const uid = vnode.context ? vnode.context._uid : null;

  if (!el._onScroll[uid]) {
    return;
  }

  const { handler, options, target = el } = el._onScroll[uid];

  target.removeEventListener("scroll", handler, options);

  delete el._onScroll[uid];
}

export const Scroll = {
  mounted,
  unmounted,
};

export default Scroll;
