import client from "@/utils/axios";
import dayjs from "dayjs";
import cloneDeep from "lodash/cloneDeep";
import { BigNumber } from "bignumber.js";
import { add } from '@/utils/math';

import { getRoundedDownValue, filterSearch } from "@/utils/helpers";

import { useCurrency } from "@/composables/useCurrency";

const SEARCH_FIELDS = [
  "currency",
  "balance",
  "rate",
  "period",
  "amount",
  "accruedInterest",
  "formattedRate",
  "formattedAccruedInterest",
  "startDate",
  "endDate",
  "totalAmount",
  "currencyFullName",
  "formattedAmount",
];

export default {
  namespaced: true,

  state: {
    options: [],
    deposits: [],
    repayments: [],
    search: "",
    currency: [],
    statuses: {
      Processing: false,
      Rejected: false,
      Executed: false,
    },
    startDateRange: null,
    endDateRange: null,
    amounts: {
      from: null,
      to: null,
    },
    filters: {},
    depositsTotal: {
      ETH: 0,
      BTC: 0,
      USDT: 0,
    },
  },

  actions: {
    async getDepositOptions({ commit }) {
      try {
        const params = {
          productType: "Deposit",
          subProductType: "Earn",
        };

        const { data } = await client.get("/api/treasury/tariffs", { params });

        commit("setDepositOptions", data || []);
      } catch {
        commit("setDepositOptions", []);
      }
    },

    async getDeposits({ commit }) {
      try {
        const params = {
          productType: "Deposit",
          subProductType: "Earn",
        };

        const { data } = await client.get("/api/treasury/transactions", {
          params,
        });

        commit("setDeposits", data?.transactions || []);
        commit("setDepositsTotal", data?.total);
      } catch {
        commit("setDeposits", []);
        commit("setDepositsTotal", 0);
      }
    },

    async getDepositRepayments({ commit }, transactionId) {
      try {
        const { data } = await client.get(
          `/api/treasury/transactions/${transactionId}/repayments`
        );

        commit("setDepositRepayments", data || []);
      } catch {
        commit("setDepositRepayments", []);
      }
    },

    async getDepositInterest(_, params) {
      try {
        const { data } = await client.get(
          "/api/treasury/transactions/accrued-interest",
          { params }
        );

        return data;
      } catch (e) {
        console.log("Failed to fetch accrued interest", e);
      }
    },

    async getDepositRepaymentInterest(_, payload) {
      try {
        const params = {
          amount: payload.amount,
        };

        const { data } = await client.get(
          `/api/treasury/transactions/${payload.transactionId}/accrued-interest-after-drawdown`,
          { params }
        );

        return data;
      } catch (e) {
        console.log("Failed to fetch accrued interest", e);
      }
    },

    async makeDeposit(_, payload) {
      return await client.post(`/api/treasury/transactions`, payload);
    },

    async makeRepayment(_, payload) {
      return await client.post(
        `/api/treasury/transactions/${payload.transactionId}/repayments`,
        payload
      );
    },

    updateSearch({ commit }, value) {
      commit("setSearch", value);
    },

    updateCurrency({ commit }, value) {
      commit("setCurrency", value);
    },

    updateStartDateRange({ commit }, value) {
      commit("setStartDateRange", value);
    },

    updateEndDateRange({ commit }, value) {
      commit("setEndDateRange", value);
    },

    updateStatuses({ commit }, value) {
      commit("setStatuses", value);
    },

    updateAmounts({ commit }, value) {
      commit("setAmounts", value);
    },

    updateFilters({ state, commit }) {
      const { currency, startDateRange, endDateRange, statuses, amounts } =
        state;

      commit(
        "setFilters",
        cloneDeep({
          currency,
          startDateRange,
          endDateRange,
          statuses,
          amounts,
        })
      );
    },

    resetFilters({ commit }) {
      commit("setCurrency", []);
      commit("setStartDateRange", null);
      commit("setEndDateRange", null);
      commit("setStatuses", {
        PROCESSING: false,
        FAILED: false,
        COMPLETED: false,
      });
      commit("setAmounts", {
        from: null,
        to: null,
      });
      commit("setFilters", {});
    },
  },

  getters: {
    earnTransactionsTotal(_, getters) {
      const { activeDeposits } = getters;

      return activeDeposits.reduce((memo, item) => {
        const { currency, totalAmount, convertedAmountRaw } = item;
        memo.total[currency] = add(memo.total[currency] ?? 0, totalAmount);
        memo.totalConverted[currency] = add(memo.totalConverted[currency] ?? 0, convertedAmountRaw);

        return memo;
      }, { total: {}, totalConverted: {} });
      
    },

    totalEarnings(state) {
      const { convertTo } = useCurrency();
      return state.depositsTotal[convertTo.value];
    },

    formattedDeposits(state, _, __, rootGetters) {
      const { convertTo } = useCurrency();
      const { getCurrencyFullName } = rootGetters;
      const { deposits, search, filters } = state;

      return deposits
        .map((deposit) => {
          return {
            ...deposit,
            period: deposit.term,
            formattedAccruedInterest: getRoundedDownValue(
              deposit?.accruedInterest,
              deposit.currency
            ),
            convertedAccruedInterest: getRoundedDownValue(
              deposit?.accruedInterestConverted?.[convertTo.value] ?? 0
            ),
            formattedAmount: getRoundedDownValue(
              deposit.amount,
              deposit.currency
            ),
            convertedAmount: getRoundedDownValue(
              deposit.amountConverted[convertTo.value]
            ),
            convertedAmountRaw: deposit.amountConverted[convertTo.value],
            formattedTotalAmount: getRoundedDownValue(
              deposit.totalAmount,
              deposit.currency
            ),
            convertedTotalAmount: getRoundedDownValue(
              deposit.totalAmountConverted[convertTo.value]
            ),
            currencyFullName: getCurrencyFullName(deposit.currency),
            startDate: dayjs.utc(deposit.dateFrom).format("DD MMM YYYY"),
            endDate: deposit.dateTo
              ? dayjs.utc(deposit.dateTo).format("DD MMM YYYY")
              : "-",
          };
        })
        .filter((deposit) => {
          let result = filterSearch(deposit, search, SEARCH_FIELDS);

          if (filters?.currency?.length) {
            const filterCurrencies = filters.currency?.map(
              (item) => item.value
            );

            result = result && filterCurrencies.includes(deposit.currency);
          }

          if (filters.startDateRange) {
            const { start, end } = filters.startDateRange;

            result =
              result &&
              dayjs(deposit.dateFrom).isBetween(
                dayjs(start),
                dayjs(end),
                "day",
                "[]"
              );
          }

          if (filters.endDateRange && deposit.dateTo) {
            const { start, end } = filters.endDateRange;

            result =
              result &&
              dayjs(deposit.dateTo).isBetween(
                dayjs(start),
                dayjs(end),
                "day",
                "[]"
              );
          }

          if (filters?.amounts?.from || filters?.amounts?.to) {
            const { from, to } = filters.amounts;

            const depositBN = new BigNumber(deposit.amount);
            const fromBN = new BigNumber(from);
            const toBN = new BigNumber(to);

            if (from && !to) {
              result = result && depositBN.isGreaterThanOrEqualTo(fromBN);
            }

            if (!from && to) {
              result = result && depositBN.isLessThanOrEqualTo(toBN);
            }

            if (from && to) {
              result =
                result &&
                depositBN.isGreaterThanOrEqualTo(fromBN) &&
                depositBN.isLessThanOrEqualTo(toBN);
            }
          }

          if (filters.statuses) {
            const values = Object.entries(filters.statuses).reduce(
              (memo, [key, value]) => {
                if (value) {
                  memo.push(key);
                }
                return memo;
              },
              []
            );

            if (values.length) {
              result = result && values.includes(deposit.status);
            }
          }

          return result;
        });
    },

    activeDeposits(_, getters) {
      const { formattedDeposits } = getters;

      return formattedDeposits.filter((deposit) => deposit.isActive);
    },

    closedDeposits(_, getters) {
      const { formattedDeposits } = getters;

      return formattedDeposits.filter((deposit) => !deposit.isActive);
    },

    options(state, getters) {
      const { search } = state;
      const { currencies } = getters;

      return currencies.filter((option) =>
        filterSearch(option, search, SEARCH_FIELDS)
      );
    },

    periods(state) {
      const { options } = state;

      return options.reduce((acc, curr) => {
        if (!acc[curr.currency]) {
          acc[curr.currency] = [];
        }

        acc[curr.currency].push(curr);

        return acc;
      }, {});
    },

    currencies(state, _, __, rootGetters) {
      const { options } = state;
      const { convertTo } = useCurrency();
      const { getCurrencyFullName } = rootGetters;

      return options.map((option) => {
        return {
          ...option,
          balance: getRoundedDownValue(option.balance, option.currency),
          convertedBalance: getRoundedDownValue(
            option.balanceConverted[convertTo.value]
          ),
          convertedBalanceRaw: option.balanceConverted[convertTo.value],
          formattedRate: `${option.rate}%`,
          currencyFullName: getCurrencyFullName(option.currency),
        };
      });
    },

    termsOfUseUrl() {
      return `/terms-of-use/BEQUANT Prime Interest Earn Terms of Use (August 2022).pdf`;
    },
  },

  mutations: {
    setDepositOptions(state, options) {
      state.options = options;
    },

    setDeposits(state, deposits) {
      state.deposits = deposits;
    },

    setDepositsTotal(state, depositsTotal) {
      state.depositsTotal = depositsTotal;
    },

    setDepositRepayments(state, repayments) {
      state.repayments = repayments;
    },

    setSearch(state, value) {
      state.search = value;
    },

    setCurrency(state, value) {
      state.currency = value;
    },

    setStartDateRange(state, value) {
      state.startDateRange = value;
    },

    setEndDateRange(state, value) {
      state.endDateRange = value;
    },

    setStatuses(state, value) {
      state.statuses = value;
    },

    setAmounts(state, value) {
      state.amounts = value;
    },

    setFilters(state, value) {
      state.filters = value;
    },
  },
};
