export const CURRENCIES = {
  BTC: "BTC",
  BNB: "BNB",
  BUSD: "BUSD",
  ETH: "ETH",
  USD: "USD",
  USDC: "USDC",
  USDT: "USDT",
  USDB: "USDB",
  USDT20: "USDT20",
  USDTRX: "USDTRX",
  TUSD: "TUSD",
  DAI: "DAI",
  EOSDT: "EOSDT",
  PAX: "PAX",
  XBT: "XBT",
  ADA: "ADA",
};
