import client from "@/utils/axios";
import { formatCurrencyValue } from "@/utils/helpers";

import getFullAccountsList from "@/composables/getFullAccountsList";

export default {
  namespaced: true,

  state: {
    fees: [],
    chargingAccount: null,
  },

  actions: {
    async loadChargingAccount({ commit }) {
      try {
        const { data } = await client.get("/api/charging/account");

        commit("setChargingAccount", data);
      } catch {
        // nothing
      }
    },

    async saveChargingAccount({ commit }, { apiKeyId, accountType }) {
      const { data } = await client.put(`/api/charging/account`, {
        apiKeyId,
        accountType,
      });

      commit("setChargingAccount", data);
    },

    async loadFees({ commit }) {
      try {
        const { data } = await client.get("/api/my/fees");

        commit("setFees", data || []);
      } catch {
        commit("setFees", []);
      }
    },
  },

  getters: {
    fees(state, _, __, rootGetters) {
      const { getExchangeFullName } = rootGetters;

      return state.fees.map((item) => {
        return {
          ...item,
          exchangeFullName: getExchangeFullName(item.exchangeId),
        };
      });
    },

    feeCurrency(state, getters) {
      const { minFee } = getters;

      return state.fees[0]?.feeCurrency || minFee?.currency || "";
    },

    feeSchemeType(state) {
      return state.fees[0]?.feeSchemeType ?? "";
    },

    feeScheme(state) {
      return state.fees[0]?.feeScheme ?? "";
    },

    minFee(state) {
      return state.fees[0]?.minFee ?? "";
    },

    feeAmount(state, getters) {
      const { fees } = state;
      const { feeSchemeType, feeScheme } = getters;

      if (feeSchemeType === "portfolio-based") {
        if (feeScheme === "fixed") {
          return formatCurrencyValue(fees[0].feeAmount);
        }
        if (feeScheme === "profit-sharing") {
          return fees[0].profitSharing;
        }
      }

      return "";
    },

    feeSchemeOptions(state) {
      const { fees } = state;

      return fees.reduce((memo, current) => {
        if (!memo.includes(current.feeScheme)) {
          memo.push(current.feeScheme);
        }
        return memo;
      }, []);
    },

    instrumentSubtypeExists(state) {
      const { fees } = state;

      return fees.some(
        (item) =>
          item.instrumentSubtype !== undefined &&
          item.instrumentSubtype !== null
      );
    },

    accountsOptions(_, getters, __, rootGetters) {
      const { feeCurrency } = getters;
      const { exchangeNames } = rootGetters;

      return getFullAccountsList(feeCurrency).filter(
        (item) => item.exchangeId !== exchangeNames.FTX
      );
    },

    account(state, getters) {
      const { chargingAccount } = state;
      const { feeCurrency, accountsOptions } = getters;

      if (!chargingAccount) {
        return null;
      }

      return accountsOptions.find(
        (account) =>
          chargingAccount.apiKeyId === account.apiKeyId &&
          feeCurrency === account.currency
      );
    },
  },

  mutations: {
    setFees(state, data) {
      state.fees = data;
    },

    setChargingAccount(state, data) {
      state.chargingAccount = data;
    },
  },
};
