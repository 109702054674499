import store from "@/store/store";

import { WEBSOCKET_INTERVALS } from "@/constants";

let transactions = [];
let timeout = null;

export default (payload) => {
  clearTimeout(timeout);

  transactions = [...payload, ...transactions];

  if (!transactions.length) {
    return;
  }

  timeout = setTimeout(() => {
    store.dispatch("transactions/update", transactions);
    transactions = [];
  }, WEBSOCKET_INTERVALS.TRANSACTIONS_DEBOUNCE);
};
