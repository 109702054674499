import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  key: 1,
  "class": "flex flex-col overflow-auto bg-secondary h-full w-full min-w-[1024px]"
};
var _hoisted_2 = {
  key: 0,
  "class": "py-[22px]"
};
var _hoisted_3 = {
  "class": "container"
};
var _hoisted_4 = {
  "class": "font-bold text-2xl"
};
import Header from "@/layouts/main-layout/Header.vue";
import Footer from "@/layouts/main-layout/Footer.vue";

import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
export default {
  __name: 'PublicLayout',
  setup: function setup(__props) {
    var store = useStore();
    var route = useRoute();
    var isTitle = computed(function () {
      var _route$meta;
      return !!(route !== null && route !== void 0 && (_route$meta = route.meta) !== null && _route$meta !== void 0 && _route$meta.title);
    });
    return function (_ctx, _cache) {
      var _component_Restricted = _resolveComponent("Restricted");
      var _component_RouterView = _resolveComponent("RouterView");
      return _unref(store).state.isRestricted ? (_openBlock(), _createBlock(_component_Restricted, {
        key: 0
      })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(Header, {
        empty: !_unref(store).state.auth.user
      }, null, 8, ["empty"]), !_unref(store).state.auth.user && _unref(isTitle) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_unref(route).meta.title), 1)])])) : _createCommentVNode("", true), _createElementVNode("div", {
        "class": _normalizeClass(["container flex flex-col", {
          'pt-2xl': !_unref(isTitle)
        }])
      }, [_createVNode(_component_RouterView)], 2), _createVNode(Footer, {
        empty: !_unref(store).state.auth.user
      }, null, 8, ["empty"])]));
    };
  }
};