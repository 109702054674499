import "core-js/modules/es.number.constructor.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue";
var _hoisted_1 = {
  "class": "relative"
};
var _hoisted_2 = {
  key: 0,
  "class": "absolute -top-[4px] -right-[4px] flex items-center justify-center w-[12px] h-[12px] text-[8px] text-secondary rounded-full bg-primary"
};
var _hoisted_3 = {
  key: 0,
  "class": "ml-md text-lg"
};
export default {
  __name: 'FiltersButton',
  props: {
    label: {
      type: Boolean,
      "default": true
    },
    count: {
      type: Number,
      "default": 0
    }
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      var _component_PBIcon = _resolveComponent("PBIcon");
      return _openBlock(), _createElementBlock("div", {
        "class": _normalizeClass(["flex items-center cursor-pointer transition-colors hover:text-primary font-semibold", {
          'text-primary': !!__props.count
        }])
      }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_PBIcon, {
        name: "filters",
        w: 24,
        h: 24
      }), !!__props.count ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(__props.count), 1)) : _createCommentVNode("", true)]), __props.label ? (_openBlock(), _createElementBlock("span", _hoisted_3, "View filters")) : _createCommentVNode("", true)], 2);
    };
  }
};