import client from "@/utils/axios";

const formatApiKeyResponse = ({ username, ...rest }) => {
  return {
    ...rest,
    apiKey: username,
  };
};

const formatDeleteApiKeyRequest = ({ apiKey }) => {
  return {
    username: apiKey,
  };
};

export default {
  namespaced: true,

  state: {
    apiKeys: [],
  },

  actions: {
    async fetchApiKeys({ commit }) {
      try {
        const { data } = await client.get("/api/publicapikeys");

        const formattedApiKeys = data.map(formatApiKeyResponse);

        commit("setApiKeys", formattedApiKeys);
      } catch (e) {
        console.log("Failed to load api keys", e);
      }
    },

    async createApiKey({ state, commit }) {
      const { data } = await client.post("/api/publicapikeys");

      const formattedApiKey = formatApiKeyResponse(data);

      commit("setApiKeys", state.apiKeys.concat(formattedApiKey));
    },

    async deleteApiKey({ state, commit }, { apiKey }) {
      const { apiKeys } = state;

      const index = apiKeys.findIndex((item) => item.apiKey === apiKey);
      const params = formatDeleteApiKeyRequest(apiKeys[index]);

      await client.delete("/api/publicapikeys", { data: params });

      const updatedApiKeys = apiKeys
        .slice(0, index)
        .concat(apiKeys.slice(index + 1));

      commit("setApiKeys", updatedApiKeys);
    },
  },

  mutations: {
    setApiKeys(state, data) {
      state.apiKeys = data;
    },
  },
};
