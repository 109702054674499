import client from "@/utils/axios";

import { useCurrency } from '@/composables/useCurrency';

export default {
  namespaced: true,

  state: {
    riskData: null,
  },

  actions: {
    async loadRiskData({ commit }) {
      try {
        const { data } = await client.get("/api/v2/my/balance/assets");

        commit("setRiskData", data || null);
      } catch {
        // do nothing
      }
    },
  },

  getters: {
    riskData(state, _, __, rootGetters) {
      const { riskData } = state;
      const { getPropertyValue } = rootGetters;
      const { convertTo } = useCurrency();

      if (riskData === null) {
        return {};
      }

      const riskModel = riskData.riskModel === "svf" ? "rr" : "ltv";

      
      const totalOrEligibleCollateral = getPropertyValue(
        riskData.totalCollateralConverted[convertTo.value],
      );
      const freeCollateral = getPropertyValue(
        riskData.availableConverted[convertTo.value],
        totalOrEligibleCollateral
      );
      const discountedCollateral = getPropertyValue(
        riskData.discountedCollateralConverted[convertTo.value],
      );
      const totalMargin = riskData.totalMarginConverted[convertTo.value];

      return {
        freeCollateral,
        totalOrEligibleCollateral,
        discountedCollateral,
        totalMargin,
        riskModel,
        riskRatio: riskData.riskRatio,
        riskRatioInitial: riskData.riskRatioInitial,
        riskRatioLiquidation: riskData.riskRatioLiquidation,
      };
    },
  },

  mutations: {
    setRiskData(state, data) {
      state.riskData = data;
    },
  },
};
