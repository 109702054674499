import _slicedToArray from "/app/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import _typeof from "/app/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.string.small.js";
import { unref as _unref, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";

import { computed } from 'vue';
export default {
  __name: 'PBBadge',
  props: {
    close: Boolean,
    small: Boolean,
    bordered: Boolean,
    label: Boolean,
    color: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  emits: ["close"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var colorClass = computed(function () {
      var color = null;
      switch (_typeof(props.color)) {
        case "string":
          color = props.color;
          break;
        case "object":
          color = Object.entries(props.color).find(function (_ref2) {
            var _ref3 = _slicedToArray(_ref2, 2),
              key = _ref3[0],
              value = _ref3[1];
            return value && key;
          });
          break;
      }
      return color;
    });
    return function (_ctx, _cache) {
      var _component_PBIcon = _resolveComponent("PBIcon");
      return _openBlock(), _createElementBlock("span", {
        "class": _normalizeClass(["PBBadge inline-flex items-center justify-between rounded-md font-semibold whitespace-nowrap", [_unref(colorClass), {
          small: __props.small,
          bordered: __props.bordered,
          label: __props.label
        }]])
      }, [_renderSlot(_ctx.$slots, "default"), __props.close ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = _withModifiers(function ($event) {
          return emit('close');
        }, ["stop"]))
      }, [_createVNode(_component_PBIcon, {
        name: "close",
        w: 12,
        h: 12
      })])) : _createCommentVNode("", true)], 2);
    };
  }
};