export const EXCHANGE_CONTRACT_TYPES = {
  SPOT: "spot",
  FUTURE: "future",
  PERPETUAL: "perpetual",
  OPTION: "option",
};

export const FEE_GET_MORE_INFO_TEXT =
  "For this fee rate please contact your sales representative";

export const TRADING_EXCHANGES_MAP = {
  binance_OMS: "binance",
  bequant_OMS: "bequant",
  hitbtc_OMS: "hitbtc",
  kraken_OMS: "kraken",
};
