import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";

import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
var _hoisted_1 = {
  "class": "flex flex-1 flex-wrap w-full justify-start"
};
export default {
  __name: 'Defi',
  setup: function setup(__props) {
    var store = useStore();
    var router = useRouter();
    return function (_ctx, _cache) {
      var _component_RouterView = _resolveComponent("RouterView");
      var _component_PBStatus = _resolveComponent("PBStatus");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_unref(store).getters['defi/defiAvailable'] && _unref(store).state.defi.balanceSpot ? (_openBlock(), _createBlock(_component_RouterView, {
        key: 0
      })) : _createCommentVNode("", true), !_unref(store).getters['defi/defiAvailable'] || _unref(store).getters['defi/defiAvailable'] && !_unref(store).state.defi.balanceSpot ? (_openBlock(), _createBlock(_component_PBStatus, {
        key: 1,
        warning: "",
        message: "DeFi account not found or you don’t have permissions",
        "primary-text": "Go Back",
        "class": "text-center",
        onPrimary: _cache[0] || (_cache[0] = function ($event) {
          return _unref(router).go(-1);
        })
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(" Please contact support and your sales representative to open the DeFi account. ")];
        }),
        _: 1
      })) : _createCommentVNode("", true)]);
    };
  }
};