import { CURRENCIES } from "@/constants/currencies";
var CONVERT_CURRENCY_TO_ALIAS_MAP = {
  BTC: "BTC",
  ETH: "ETH",
  USDT: "USD"
};
var convertTo = useLocalStorage("bq:currency", CURRENCIES.USDT);
var convertToView = ref(CONVERT_CURRENCY_TO_ALIAS_MAP[convertTo.value]);
export function useCurrency() {
  function setConvertTo(currency) {
    convertTo.value = currency;
    convertToView.value = CONVERT_CURRENCY_TO_ALIAS_MAP[currency];
  }
  return {
    convertTo: convertTo,
    convertToView: convertToView,
    setConvertTo: setConvertTo
  };
}