import { BigNumber } from "bignumber.js";
import dayjs from "dayjs";
import { READABLE_DATE_FORMAT } from "@/constants";
import { abbreviateNumber } from "@/utils/helpers";

export const numbro = (number, currency, options = {}) => {
  const store = require("@/store/store");
  const state = store.default.state;
  const BN_CONFIG = {
    decimalSeparator: ".",
    groupSeparator: ",",
    groupSize: 3,
  };
  let {
    rule = state.config.uiFormatters.ROUNDING_RULES.DEFAULT,
    mantissa = state.config.uiFormatters.SHORT_PRECISION_MANTISSA,
    roundDown = false,
    noSeparator = false,
    average = false,
  } = options;

  const isBtc = currency?.match(/btc/i);
  const normalizedNumber = +number || 0;

  if (noSeparator) {
    BN_CONFIG.groupSeparator = "";
    BN_CONFIG.groupSize = 0;
  }

  BigNumber.set({ FORMAT: BN_CONFIG });

  if (roundDown) {
    BigNumber.set({ FORMAT: BN_CONFIG, ROUNDING_MODE: BigNumber.ROUND_DOWN });
  }

  switch (rule) {
    case state.config.uiFormatters.ROUNDING_RULES.DEFAULT:
      mantissa = isBtc
        ? state.config.uiFormatters.BTC_PRECISION_MANTISSA
        : state.config.uiFormatters.SHORT_PRECISION_MANTISSA;
      break;
    case state.config.uiFormatters.ROUNDING_RULES.LONG_PRECISION:
      mantissa = state.config.uiFormatters.LONG_PRECISION_MANTISSA;
      break;
    case state.config.uiFormatters.ROUNDING_RULES.SHORT_PRECISION:
      mantissa = state.config.uiFormatters.SHORT_PRECISION_MANTISSA;
      break;
    case state.config.uiFormatters.ROUNDING_RULES.MAX_PRECISION:
      mantissa = state.config.uiFormatters.MAX_PRECISION_MANTISSA;
      break;
  }

  let result = new BigNumber(normalizedNumber)
    .decimalPlaces(mantissa)
    .toFormat();

  if (average) {
    result = abbreviateNumber(result);
  }

  return result;
};

export const capitalize = (value) => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0)?.toUpperCase() + value.slice(1);
};

export const readableDate = (value, notUtc) => {
  return notUtc
    ? dayjs(value).format(READABLE_DATE_FORMAT)
    : dayjs(value).utc().format(READABLE_DATE_FORMAT);
};

export const readableShortDate = (value, notUtc, customFormat) => {
  return notUtc
    ? dayjs(value).format(customFormat ?? "DD MMM YYYY")
    : dayjs(value)
        .utc()
        .format(customFormat ?? "DD MMM YYYY");
};

export const date = (value) => {
  const store = require("@/store/store");
  return dayjs(value)
    .utc()
    .format(store.default.state.config.uiFormatters.DATE_FORMAT);
};

export const time = (value) => {
  const store = require("@/store/store");
  return dayjs(value)
    .utc()
    .format(store.default.state.config.uiFormatters.TIME_FORMAT);
};

export const cutText = (value = "", after = 8) => {
  if (!value) {
    return "";
  }

  if (value.length === after || value.length < after) {
    return value;
  }

  const chars = value.toString().slice(0, after);

  return `${chars} ...`;
};

export const trimText = (value, len) => {
  if (!value) {
    return "";
  }

  if (value.length === len) {
    return value;
  }

  const lastCharacters = value.toString().slice(-len);
  return `...${lastCharacters}`;
};

export const shortenString = (str, len = 13, start = 4, end = 6) => {
  if (str.length <= len) {
    return str;
  }

  return `${str.substr(0, start)}...${str.substr(-end)}`;
};
