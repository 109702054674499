import store from "@/store/store";
import { getFromLocalStorage } from "@/utils/helpers";

export default async (_, __, next) => {
  try {
    if (!getFromLocalStorage("bq:token")) {
      return next({ path: "/login" });
    }

    await Promise.all([
      store.dispatch("auth/fetchUser"),
      store.dispatch("config/fetch")
    ])
    
    return next();
  } catch {
    return next({ path: "/login" })
  }
};
