import "core-js/modules/es.function.name.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { exportCsv } from "@/utils/helpers";
export default {
  __name: 'ExportCsvButton',
  props: {
    disabled: Boolean,
    data: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    name: {
      type: String,
      "default": ""
    }
  },
  setup: function setup(__props) {
    var props = __props;
    function handleExportCsv() {
      if (props.data.length) {
        exportCsv(props.data, props.name);
      }
    }
    return function (_ctx, _cache) {
      var _component_PBIcon = _resolveComponent("PBIcon");
      return _openBlock(), _createElementBlock("div", {
        "class": _normalizeClass(["group cursor-pointer transition-opacity", {
          'opacity-50 pointer-events-none': __props.disabled
        }]),
        role: "button",
        onClick: handleExportCsv
      }, [_createVNode(_component_PBIcon, {
        name: "export",
        w: 20,
        h: 20,
        "class": "group-hover:text-primary transition-colors"
      })], 2);
    };
  }
};